<template>
    <div class="vlMenus">
        <div class="vlMenu wf-mplus1p" :class="{selected: index == menuId}" v-for="(menu, index) in menuLists" :key="index" @click="changeMenu(index)">
            <p>{{ menu.label }}</p>
        </div>
        <div class="vlMenu" @click="logout">
            <p>Logout</p>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
    props: {
        menuLists: Array
    },
    setup() {
        const store = useStore();
        const menuId = computed(() => {return store.getters["Video/menu"]})

        const load = async () => {
            // console.log(props.menuLists);
        }

        const changeMenu = (id) => {
            store.dispatch('Video/setMenu', id);
        }

        const logout = () => {
            store.dispatch('Video/logout');
        }

        load();
        return {
            menuId,
            changeMenu,
            logout
        }
    }
}
</script>

<style scoped>
.vlMenus{
    width: 100%; height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.vlMenu{
    width: 100%; height: 98px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(116, 116, 116);
    font-weight: bold;
    font-size: 32px;
    cursor: pointer;
    transition: 0.5s;
    border-bottom: 2px solid rgb(114, 114, 114);
}
.vlMenu:hover,
.vlMenu.selected{
    color: white;
}
</style>