<template>
    <div class="privateContent" v-if="channels">
        <h2>強制的に再生を開始します</h2>
        <a class="btn" :class="{disabled: !isAllowStart}" @click="startListen">開始</a>
        <div class="guildPreview">
            <div class="guilds">
                <div class="guild" :class="{selected: guild.gid == gid}" v-for="guild in channels" :key="guild" @click="gid = guild.gid; cid=null">
                    <div class="guildIcon">
                        <img :src="guild.image || unknown" />
                    </div>    
                    <p class="guildName" :title="guild.guildName">: {{guild.guildName}}</p>
                </div>
            </div>
            <div class="channels">
                <template v-if="gid">
                    <div class="channel" :class="{selected: channel.id == cid}" v-for="channel in channels[gid].channels" :key="channel" @click="cid = channel.id">
                        <p>{{channel.name}} ({{channel.memberCount}}) </p>
                    </div>
                </template>
            </div>
            <div class="members">
                <template v-if="cid">
                    <div class="member" v-for="member in channels[gid].channels[cid].members" :key="member">
                        <div class="guildIcon">
                            <img :src="member.avatar || unknown" />
                        </div>    
                        <p>: {{member.name}}</p>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<style scoped>
.guildPreview{
    display: flex;
    width: 100%; height: 600px;
    border: 5x solid #1e2124;
    border-collapse: collapse;
}
.guilds,
.channels,
.members{
    padding: 10px;
    width: 33.3%; height: 100%;
    border: 3px solid #1e2124;
    overflow: auto;
}
.guild{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    font-size: xx-large;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    user-select: none;
}

.guildIcon img{
    width: 100%;
}
.guildIcon{
    width: 64px; height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: 	#424549;
    overflow: hidden;
    transition: 0.5s;
    border-radius: 50%;
}

.guild:hover .guildIcon,
.guild.selected .guildIcon{
    background-color: 	#7289da;
    
    border-radius: 30%;
}
.guild.selected{
    background-color: #424549;
    transition: 0.5s;
}

.channels{
    font-size: large;
}
.channel{
    padding: 10px;
    cursor: pointer;
    user-select: none;
}

.channel.selected{
    background-color: #424549;
}
.member{
    display: flex;
    align-items: center;
    font-size: x-large;
    user-select: none;
    cursor: default;
    font-weight: bold;
}
.guildName{
    max-width: 270px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import unknown from '@/assets/unknown.png'

export default {
    setup(){
        const store = useStore();
        const channels = ref({});

        const cid = ref("");
        const gid = ref("");

        const load = async () => {
            channels.value = await store.dispatch('Api/callApi', {url: "private/nowjoiningChannels"});
            // console.log(channels.value);
        };
        load();

        const isAllowStart = computed(() => {
            // console.log(gid.value, cid.value)
            if(!gid.value) return false;
            if(!cid.value) return false;

            let num = channels.value[gid.value].channels[cid.value].memberCount;
            return 0 < num
        })

        const startListen = async () => {
            if(!isAllowStart.value){
                await store.dispatch('Common/showModal', {text: "チャンネルが指定されていません"});
                return;
            }

            let rsp = await store.dispatch('Api/callApi', {url: "private/startListen", data: {cid: cid.value}});

            if(rsp) {
                await store.dispatch('Common/showModal', {text: "開始しました。"});
            }else{
                await store.dispatch('Common/showModal', {text: "開始に失敗しました。"});
            }
        }

        return {
            channels,
            cid,
            gid,
            unknown,
            isAllowStart,
            startListen
        }
    }
}
</script>