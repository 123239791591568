<template>
    <div class="menuPrivateSide">
        <template v-for="m, index in menu" :key="index">
            <div v-if="!m.admin || (m.admin && isAdmin)" class="menuRow" :class="{selected: index == menuId}"  @click="changeMenu(index)">
                <div class="menuIconDiv"><img :src="m.image"/></div>
                <p class="menuLabel">{{m.label}}</p>
            </div>
        </template>
        <div class="menuRow">
            <div class="menuIconDiv"><img src="../assets/menu/next.png"/></div>
            <p class="menuLabel">メンバー</p>
        </div>
        <div class="menuRow" @click="logout">
            <div class="menuIconDiv"><img src="../assets/menu/logout.png"/></div>
            <p class="menuLabel">ログアウト</p>
        </div>
    </div>
</template>
<script>
import router from '@/router';
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';

export default {
    props: {
        menu: {},
        userInfo: {},
    },
    setup(){
        const store = useStore();
        const menuId = ref(1);

        const isAdmin = ref();

        const load = async () => {
            menuId.value = await store.getters["Common/getMenu"];
            // console.log(menuId.value);
            // console.log(user.value);
            isAdmin.value = await store.dispatch('Api/callApi', {url: "private/isAdmin"});
            // console.log(isAdmin.value);
        }
        load();

        const getMenuId = computed(() => {
            return store.getters["Common/getMenu"];
        })

        watch(getMenuId, (v) => {
            menuId.value = v;
        })

        const changeMenu = async (id) => {
            await store.dispatch('Common/setMenu', id);
        }

        const logout = async () => {
            await store.dispatch('Api/logout');
            router.push('/');
        }

        const isMember = computed(() => {
            return store.getters["Common/isMember"];
        })

        return {
            menuId,
            changeMenu,
            logout,
            isAdmin,
            isMember
        }
    }
}
</script>
<style scoped>
@import url('../css/menu.css');
</style>