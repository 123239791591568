<template>
    <div class="channelsIconsVue">
        <div class="channelAuthor" :class="{opening: l.channel_url == openingModalUrl}" v-for="l in list" :key="l" :title="`${l.name}(${l.cnt}曲)`" @click="openURL(l.channel_url)">
            <img v-if="l.user_thumbnails" class="authorIcon" :src="l.user_thumbnails" />
            <div v-else class="authorIconDiv" :style="{backgroundColor: randomColor()}"><p>{{ l.name[0] }}</p></div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    props: {
        list: Object
    },
    setup() {
        const store = useStore();
        const openingModalUrl = ref(null);

        const colors = [
            "#00579C"
        ];

        const openURL = async (url) => {
            openingModalUrl.value = url;
            const cnf = await store.dispatch('Common/openConfirm', {header: "外部のページ", text: `外部のページに飛びます。<br />よろしいですか？<br />URL: ${url}`});
            if (cnf) {
                let a = document.createElement("a");
                a.href = url;
                a.target = "_blank";
                a.click();
                a.remove();
            }
            openingModalUrl.value = null; 
        };

        const randomColor = () => {
            const clLength = colors.length;
            const index = Math.floor(Math.random() * clLength);
            return colors[index];
        }

        return {
            randomColor,
            openURL,
            openingModalUrl
        };
    }
}
</script>

<style scoped>
.channelsIconsVue{
    display: flex;
    margin-bottom: 10px;
}
.channelAuthor{
    position: relative;
    width: calc(10% - 20px);
    border-radius: 50%;
    overflow: hidden;
    margin: 10px;
    cursor: pointer;
    transition: 1s;
}
.channelAuthor a{
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
}
.channelAuthor:hover,
.channelAuthor.opening{
    border-radius: 10%;
}
.authorIcon{
    height: 100%; width: 100%;
    user-select: none;
    -webkit-user-drag: none;
}
.authorIconDiv{
    height: 100%;
    display: flex;
    justify-content: center;
    font-size: 80px;
    /* font-weight: bold; */
    align-items: center;
}
</style>