<template>
  <router-view/>
  <loadingVue v-if="0 < loading.length"></loadingVue>
  <normalModalVue v-if="viewModal"></normalModalVue>
  <ConfirmModal v-if="confirmModal"></ConfirmModal>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import loadingVue from '@/components/items/loadingPage.vue';
import normalModalVue from './components/modals/normalModal.vue';
import ConfirmModal from './components/modals/confirmModal.vue';
export default {
  components: {
    loadingVue,
    normalModalVue,
    ConfirmModal,
},
  setup(){
    const store = useStore();
    const loading = ref([]);
    const viewModal = ref(false);

    const confirmModal = computed(() => {
      return store.getters["Common/getIsConfirm"];
    })

    const load = async () => {
      store.dispatch('Common/resetLoading');
      loading.value = await store.getters["Common/getLoading"];
      viewModal.value = await store.getters["Common/getModalView"];

      let protocol = location.protocol;

      if(process.env.NODE_ENV != 'development' && protocol == "http:"){
        location.href=location.href.replace('http://', 'https://');
      }

      store.dispatch('Video/ping');
    }
    load();

    const checkLoading = computed(() => {
      return store.getters["Common/getLoading"]
    })

    watch(checkLoading, (v) => {
      loading.value = v;
    })

    const getModalView = computed(() => {
      return store.getters["Common/getModalView"];
    })

    watch(getModalView, (v) => {
      viewModal.value = v;
    })

    return {
      loading,
      viewModal,
      confirmModal,
    }
  }
}
</script>

<style scoped>
@import url("./css/common.css");
@import url("./css/fonts.css");
@import url("./css/decolation.css");
@import url("./css/sp/spCommon.css");
</style>
