<template>
    <div class="searchMusicArea">
        <div><label class="openButtonArea" @click="toggleFilter" :title="filterOpendCmp ? '閉じる' : '開く'">
                <div class="openButton">
                    <p>{{ filterOpendCmp ? `-` : `+` }}</p>
                </div>詳細フィルター
            </label></div>
        <Transition>
            <div v-show="filterOpendCmp" class="searchMusicBox selectField">
                <p>投稿者:
                    <span class="authorInputArea">
                        <input type="text" list="authorList" placeholder="投稿者名" @keydown.enter="callAuthorList"
                            @input="callAuthorList" @change="updateValues" v-model="authorName" />
                        <span v-if="authorName" class="delt" title="削除" @click="authorNameDel">×</span>
                    </span>
                    <a class="btn" @click="is_authorChoose = true">補完</a>
                </p>
                <datalist id="authorList">
                    <option v-for="author in authorList" :key="author" :value="author.name"></option>
                </datalist>
                <p v-if="modelValue.isPriority_ != undefined"><label><input type="checkbox" v-model="isPriority" @change="updateValues" />優先動画のみ</label></p>
                <p v-if="modelValue.isVolume != undefined"><label><input type="checkbox" v-model="isVolume" @change="updateValues" />個別音量設定中</label></p>
            </div>
        </Transition>

        <AuthorList :isFavos="isFavos" v-if="is_authorChoose" @close="is_authorChoose = false" @put="setAuthorName">
        </AuthorList>
    </div>
</template>
<style scoped>
.v-enter-active {
    transition: opacity 1.3s ease;
}

.v-leave-active {
    transition: opacity 0.2s ease;
}

.searchMusicArea {
    width: 100%;

}

.searchMusicBox {
    width: calc(100% - 4em);
    justify-content: space-between;
    display: flex;
    background-color: rgb(109, 109, 109);
    padding: 2em 2em;
    border-radius: 30px;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.openButtonArea {
    cursor: pointer;
    user-select: none;
}

.openButton {
    display: inline-flex;
    background-color: aliceblue;
    color: black;
    margin: 0.5em;
    width: 1.5em;
    height: 1.5em;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 10px;
    border: 3px solid black;
}

.authorInputArea {
    display: inline-block;
    position: relative;
}

.authorInputArea span.delt {
    position: absolute;
    display: block;
    cursor: pointer;
    color: black;
    font-size: 0.8em;
    top: 21px;
    right: 5px;

    transform: translate(0, -50%);
}
</style>
<script>
import store from '@/store';
import { ref, watch, computed } from 'vue';
import AuthorList from './authorList.vue';

export default {
    props: {
        modelValue: {
        },
        isFavos: { type: Boolean, default: false }
    },
    setup(props, context) {
        const filterOpend = ref(false);
        const authorList = ref([]);
        const authorName = ref(undefined);
        const isPriority = ref("");
        const isVolume = ref();
        const is_authorChoose = ref(false);
        const filterOpendCmp = computed(() => {
            if (filterOpend.value === true)
                return true;
            if (authorName.value || isPriority.value)
                return true;
            return false;
        });
        const load = () => {
            console.log(props.modelValue)
            authorName.value = props.modelValue.author || "";
            isPriority.value = props.modelValue.isPriority_ || false;
            isVolume.value = props.modelValue.isVolume || false;
        };
        load();

        const setAuthorName = async (event) => {
            authorName.value = event;
            updateValues()
        }

        watch(props, () => {
            // console.log(v)
            load();
        }, { deep: true });
        const callAuthorList = async () => {
            if (authorName.value) {
                authorList.value = await store.dispatch('Api/callApi', { url: "private/getAuthorList", data: { name: authorName.value }, loading: false });
            }
            else {
                authorList.value = [];
            }
        };
        const toggleFilter = () => {
            filterOpend.value = !filterOpend.value;
        };
        const changeFilter = computed(() => {
            const data = {};
            data.author = authorName.value
            if(props.modelValue.isPriority_ !== undefined) data.isPriority_ = isPriority.value
            if(props.modelValue.isVolume !== undefined) data.isVolume = isVolume.value
            return data;
        });
        const updateValues = () => {
            // console.log(changeFilter.value);
            context.emit('update:modelValue', changeFilter.value);
            context.emit('change', true);
        }
        const authorNameDel = async () => {
            if (!authorName.value)
                return;
            authorName.value = "";
            authorList.value = [];
            updateValues();
        };
        return {
            filterOpend,
            toggleFilter,
            authorList,
            authorName,
            callAuthorList,
            isPriority,
            filterOpendCmp,
            authorNameDel,
            isVolume,
            is_authorChoose,
            setAuthorName,
            updateValues
        };
    },
    components: { AuthorList }
}
</script>