<template>
    <div>
        <h2>登録曲</h2>
        <p v-if="musics && musics[0]">偏差値: {{ musics[0].stdev }}</p>
        <div class="selectField">
            <p>表示件数: <select v-model="viewMax">
                <option value="10">10件</option>
                <option value="50">50件</option>
            </select></p>
            <p>タイトル検索: <input list="searchName" name="searchName" v-model="name" placeholder="タイトル" @change="load" @input="searchTitle" /></p>
            <datalist id="searchName">
                <option v-for="title in titleList" :key="title" :value="title.title"></option>
            </datalist>
            <p>ソート順: 
            <select v-model="sortType" @change="load">
                <option value="0">登録日</option>
                <option value="1">週間再生回数</option>
                <option value="2">全期再生回数</option>
                <option value="3">再生順</option>
                <option value="4">投稿者別</option>
                <option value="5">お気に入り登録者数順</option>
                <option value="6">更新日順</option>
            </select>
            <select v-model="sortRange" @change="load">
                <option value="0">昇順</option>
                <option value="1">降順</option>
            </select>
            </p>
        </div>
        <div class="selectField">
            <SearchMusicArea v-model="detail" @change="load"></SearchMusicArea>
        </div>
        <pageNation
            v-model="current"
            :maxPage="maxPage"
            :keyObserb="true"
            :viewMax="viewMax"
        ></pageNation>
        <table class="normal w100">
            <tr>
                <th>&nbsp;&nbsp;</th>
                <th>サムネイル</th>
                <th>タイトル</th>
                <th>投稿者</th>
                <th>BOT内再生回数</th>
                <th>偏差値</th>
                <th>お気入登録者</th>
                <th>登録日<hr />更新日</th>
                <th>最終再生日</th>
                <th></th>
                <th></th>
            </tr>
            <musicsRowVue v-for="music in musics" :key="music" :music="music" @author="detail.author=$event;load()"></musicsRowVue>
            <tr v-if="musics.length == 0">
                <td colspan="5" class="center">お気に入りに登録されている曲がありません。</td>
            </tr>
        </table>
        <pageNation
            v-model="current"
            :maxPage="maxPage"
            :viewMax="viewMax"
        ></pageNation>
    </div>
</template>
<script>
import store from '@/store';
import { ref, computed } from 'vue';
import musicsRowVue from './rows/musicsRow.vue';
import pageNation from '../items/pageNation.vue';
import SearchMusicArea from '../items/searchMusicArea.vue';

export default {
    components: {
    musicsRowVue,
    pageNation,
    SearchMusicArea
},
    setup(){
        const musics = ref([]);

        const viewMax = ref("10");
        const name = ref("");
        const sortType = ref("3");
        const sortRange = ref("1");
        const current = ref(1);

        const titleList = ref([]);
        

        const detail = ref({
            author: ""
        })

        const load = async () => {
            musics.value = await store.dispatch('Api/callApi', {url: "private/musicsInfo", data: {
                name: name.value,
                sortType: sortType.value,
                sortRange: sortRange.value,
                author: detail.value.author || ""
            }})
            // console.log(musics.value[0].author)
            
        }
        load();

        const maxPage = computed(() => {
            return Math.ceil(musics.value.length / viewMax.value);
        })


        const viewList = computed(() => {
            let end = current.value * viewMax.value;
            let start = end - viewMax.value;
            return musics.value.slice(start, end)
        })

        const searchTitle = async () => {
            titleList.value = await store.dispatch('Api/callApi', {url: "private/searchTitle", data: {
                isAll: true,
                title: name.value
            }})
        };

        return {
            musics: viewList,
            viewMax,
            name,
            sortType,
            sortRange,
            load,
            current,
            maxPage,
            detail,
            searchTitle,
            titleList
        }
    }
}
</script>