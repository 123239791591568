<template>
    <Teleport to="body">
        <div class="bg-modal">
            <div class="base-modal">
                <div class="modalHeader">{{ content.header }}</div>
                <div class="modalContent">
                    <p class="confirmModalText" v-html="content.text"></p>
                </div>
                <div class="modalFooter">
                    <!-- ボタン -->
                    <a class="btn success" @click="sendValue(true)">はい</a>
                    <a class="btn danger" @click="sendValue(false)">いいえ</a>
                </div>
            </div>
        </div>
    </Teleport>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
    setup() {
        const store = useStore();
        const content = computed(() => {
            return store.getters["Common/getConfirmText"];
        })
        const sendValue = async (value) => {
            if(typeof value !== 'boolean') return;
            await store.dispatch('Common/setConfirmValue', value);
        }

        return {
            content,
            sendValue
        }
    }
}
</script>
<style scoped>
.base-modal{
    min-width: 500px; width: 500px;
    height: 400px;
    display: block;
    border-radius: 30px !important;
    overflow: hidden;
    z-index: 101;
}
.modalHeader{
    width: 100%; height: 80px;
    background-color: #9bcbf4;
    color: white;
    display: flex; 
    justify-content: center;
    align-items: center;
}
.modalContent {
    width: calc(100% - 60px); padding: 30px; height: 180px;
}
.modalFooter{
    width: 100%; height: 80px;
    background-color: #7392f7;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modalFooter .btn{
    width: 100px; margin: 0 5px;
    text-align: center;
    font-size: x-large;
    border: none;
}
.modalFooter .btn:active{
    transition: none;
    transform: scale(0.95);
    color: white;
}
.btn.success{
    background-color: green
}
.btn.success:hover:active {
    background-color: rgb(21, 167, 21);
}
.btn.success:hover{
    background-color: rgb(21, 167, 21);
}
.btn.danger{
    background-color: red;
}
.btn.danger:active{
    background-color: rgb(255, 102, 102);
}
.btn.danger:hover{
    background-color: rgb(255, 102, 102);
}

.confirmModalText{
    width: 100%;
    overflow-wrap: break-word;
    font-size: medium;

}
</style>