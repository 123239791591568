<template>
    <div>
        <div>
            <h2>お気に入りリスト</h2>
            <p>現在登録されている動画が表示されます。<br />詳細情報が取得されていない動画は表示されません。(反映に時間がかかる場合があります。)</p>
            <p>標準偏差: {{ Math.floor(stdev * 100) / 100 }}, 平均: {{ Math.floor(avg * 100) / 100 }}</p>
            <br />
            <p>動画を追加: <input type="url" placeholder="https://youtu.be/xxxxxx" v-model="url" />&nbsp;<a class="btn" @click="regist">登録</a></p>
            <br />
            <div class="selectField">
                <p>表示件数: <select v-model="viewMax">
                    <option value="10">10件</option>
                    <option value="50">50件</option>
                </select></p>
                <p>タイトル検索: <input list="searchName" name="searchName" v-model="name" placeholder="タイトル" @change="load" @input="searchTitle" /></p>
                <datalist id="searchName">
                    <option v-for="title in titleList" :key="title" :value="title.title"></option>
                </datalist>
                <p>ソート順: 
                <select v-model="sortType" @change="load">
                    <option value="0">登録日</option>
                    <option value="1">週間再生回数</option>
                    <option value="2">全期再生回数</option>
                    <option value="3">再生順</option>
                    <option value="4">投稿者別</option>
                    <option value="6">更新日順</option>
                </select>
                <select v-model="sortRange" @change="load">
                    <option value="0">昇順</option>
                    <option value="1">降順</option>
                </select>
                </p>
                <p><a class="btn" @click="load">&#x1F504;</a>再読み込み</p>
            </div>
            <div class="selectField">
                <SearchMusicArea v-model="detail" :isFavos="true" @change="load"></SearchMusicArea>
            </div>
        </div>
        <pageNation
            v-model="current"
            :maxPage="maxPage"
            :keyObserb="true"
            :viewMax="viewMax"
        ></pageNation>
        <table class="normal w100">
            <tr>
                <th></th>
                <th class="thumbnailsFav">サムネイル</th>
                <th>タイトル</th>
                <th>投稿者</th>
                <th>再生回数(自分)</th>
                <th>偏差値</th>
                <th>登録日<hr />更新日</th>
                <th>最終再生日</th>
                <th></th>
            </tr>
            <!-- <tr v-for="vl in viewList" :key="vl" class="favRow">
                <td class="thumbnailsFav"><img :src="vl.thumbnails"/></td>
                <td>{{vl.title}}</td>
                <td>{{vl.author.name}}</td>
                <td><button class="favoButton tableChangeBtn">登録</button></td>
            </tr> -->
            <blkRowVue v-for="vl in viewList" :key="vl" :vl="vl" :stdev="stdev" @author="detail.author=$event;load()"></blkRowVue>
            <tr v-if="favos.length == 0">
                <td colspan="5" class="center">お気に入りに登録されている曲がありません。</td>
            </tr>
        </table>
        <pageNation
            v-model="current"
            :maxPage="maxPage"
            :viewMax="viewMax"
        ></pageNation>
    </div>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import blkRowVue from './rows/favosRow.vue';
import pageNation from '../items/pageNation.vue'
import SearchMusicArea from '../items/searchMusicArea.vue';

export default {
    components: {
    blkRowVue,
    pageNation,
    SearchMusicArea
},
    setup(){
        const store = useStore();
        const favos = ref([]);
        const url = ref("");
        const current = ref(1);
        const stdev = ref(null);
        const avg = ref(null);

        const titleList = ref([]);

        const sortType = ref("0");
        const sortRange = ref('1');
        const name = ref("");
        const detail = ref({
            author: '',
            isPriority_: false,
            isVolume: false,
        });
        
        const viewMax = ref("10");
        
        const maxComp = computed(() => {
            return favos.value.length
        })

        const min = ref(0);
        const load = async () => {
            // console.log(detail.value);
            favos.value = await store.dispatch('Api/callApi', {url: 'private/favorites', data: {
                type: sortType.value,
                sort: sortRange.value,
                name: name.value,
                author: detail.value.author || "",
                isPriority: detail.value.isPriority_ || false,
                isVolume: detail.value.isVolume || false
            }});
            // console.log(favos.value);
            let rsp = await store.dispatch('Api/callApi', {url: "private/calcSTDEV"});
            stdev.value = rsp.stdev;
            avg.value = rsp.avg;
        }
        load();


        const viewList = computed(() => {
            let arry = [];
            let end = current.value * viewMax.value;
            let start = end - viewMax.value;
            // console.log(start, end);
            arry = favos.value.slice(start, end);
            return arry;
        });

        const regist = async () => {
            //inputChangeStatus
            let uu = url.value;
            url.value = "";
            let rslt = await store.dispatch('Api/callApi', {url: "private/inputChangeStatus", data: {url: uu, kind: "favorite"}});
            if(rslt){
                await store.dispatch('Common/showModal', {text: "登録しました。"});
                load();
            }else{
                await store.dispatch('Common/showModal', {text: "登録に失敗しました。"});
            }
            
        }

        const openURL = async (url) => {
            const cnf = await store.dispatch('Common/openConfirm', {header: "外部のページ", text: `外部のページに飛びます。<br />よろしいですか？<br />URL: ${url}`});
            if (cnf) {
                let a = document.createElement("a");
                a.href = url;
                a.target = "_blank";
                a.click();
                a.remove();
            }
        }

        //pagenation
        const maxPage = computed(() => {
            return Math.ceil(favos.value.length / viewMax.value);
        })

        const searchTitle = async () => {
            titleList.value = await store.dispatch('Api/callApi', {url: "private/searchTitle", data: {
                isAll: false,
                title: name.value
            }, loading: false})
        };

        return {
            favos,
            viewList,
            maxPage,
            min,
            viewMax,
            maxComp,
            regist,
            url,
            openURL,
            sortRange,
            sortType,
            load,
            name,
            current,
            detail,
            stdev,
            avg,
            searchTitle,
            titleList
        }
    }
}
</script>