<template>
    <div class="privateHeader" :style="skyGradation">
        <div class="isDevCommander" v-if="isDevelop">prod: <ToggleSwitch v-model="isProd" @change="isProdUpdate" /></div>
        <IconTemp />
        <div class="clock" v-if="timeStr">
            <p>{{ timeStr.date }}</p>
            <p>{{ timeStr.time }}</p>
            <p>{{ nextEvent }}</p>
        </div>
        <moonCanvas v-if="isMoonRise" :age="weather.moon_age" :isDayTime="isSunRise" :time="time"
            :rising="{ moonriseTime, moonsetTime }" />
        <sunObject v-if="isSunRise" :time="time" :rising="{ sunriseTime, sunsetTime }" />
    </div>
</template>

<script setup>
import { useStore } from 'vuex';
import moment from 'moment';
import IconTemp from './items/iconTemp.vue';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import moonCanvas from "./weather/moonCanvas.vue"
import sunObject from './weather/sunObject.vue'
import ToggleSwitch from './items/toggleSwitch.vue';
moment.locale("ja");

const isSunGet = ref(false);

const store = useStore();
const isMoonRise = computed(() => {
    if (!weather.value) return false;
    return (moonriseTime.value.isBefore(time.value) && moonsetTime.value.isAfter(time.value));
})
const isSunRise = computed(() => {
    if (!weather.value) return false;
    return (sunriseTime.value.isBefore(time.value) && sunsetTime.value.isAfter(time.value));
})
const sunriseTime = computed(() => {
    if (!weather.value) return null;
    return moment(`${time.value.format('YYYY-MM-DD')} ${weather.value.sunrise}`, "YYYY-MM-DD HH:mm");
})
const sunsetTime = computed(() => {
    if (!weather.value) return null;
    return moment(`${time.value.format('YYYY-MM-DD')} ${weather.value.sunset}`, "YYYY-MM-DD HH:mm");
})

const moonriseTime = computed(() => {
    if (!weather.value) return null;
    return moment(weather.value.moonriseDatetime, "YYYY-MM-DD HH:mm:ss");
})
const moonsetTime = computed(() => {
    if (!weather.value) return null;
    return moment(weather.value.moonsetDatetime, "YYYY-MM-DD HH:mm:ss");
})

const isProd = ref(null);

const isDevelop = computed(() => {
    return process.env.NODE_ENV == 'development'
})
const isProdStore = computed(() => {
    if(!isDevelop.value) return false;
    return store.getters["Api/isProd"];
})

watch(isProdStore, (v) => {
    isProd.value = v;
})

const isProdUpdate = async () => {
    await store.dispatch('Api/toggleIsProd', isProd.value);
}

const nextEvent = computed(() => {
    if(!weather.value) return "";
    
    const nowTime = time.value.unix();
    const sunrise = sunriseTime.value.unix();
    const sunset = sunsetTime.value.unix();
    const moonrise = moonriseTime.value.unix();
    const moonset = moonsetTime.value.unix();

    const timeobj = {};
    timeobj[nowTime.toString()] = {time: time.value, label: "現在時刻"};
    timeobj[sunrise.toString()] = {time: sunriseTime.value, label: "日の出"};
    timeobj[sunset.toString()] = {time: sunsetTime.value, label: "日の入"};
    timeobj[moonrise.toString()] = {time: moonriseTime.value, label: "月の出"};
    timeobj[moonset.toString()] = {time: moonsetTime.value, label: "月の入"};

    const timeArray = ([nowTime, sunrise, sunset, moonrise, moonset]).sort((a, b) => a -b);
    const index = timeArray.indexOf(nowTime);
    
    const value = timeArray[index + 1];

    if(!value) return ""

    const resp = timeobj[value.toString()];

    return `${resp.label}: ${resp.time.format('HH:mm')}`;
})

const time = ref(moment());
const interval = ref(null);

const weather = ref(null);

const timeStr = computed(() => {
    return {
        date: time.value.format('YYYY年MM月DD日(ddd)'),
        time: time.value.format('HH:mm:ss')
    }
})

const getSunset = async () => {
    weather.value = await store.dispatch('Api/callApi', {
        url: "api/sunset",
        data: {}
    })
}

// マウント済み
onMounted(async () => {
    interval.value = setInterval(async () => {
        time.value = moment();
    }, 500)
    await getSunset();
    isProd.value = isProdStore.value;
})

watch(time, async (v) => {
    if (v.get('hour') == 0 && v.get('minute') % 15 == 0) {
        if (isSunGet.value == false) {
            await getSunset();
            isSunGet.value = true;
        }
    } else {
        isSunGet.value = false;
    }
})

onUnmounted(() => {
    if (interval.value) clearInterval(interval.value);
})

const skyGradation = computed(() => {
    if (!weather.value) return null;

    // 時間準備
    const beforeSunset = moment(sunsetTime.value).subtract(30, "minutes")
    const beforeSunrise = moment(sunriseTime.value).subtract(30, "minutes");
    const PI = Math.PI;

    if(time.value.isBetween(beforeSunrise, sunriseTime.value)) {
        // 日の出
        const percent = (time.value.unix() - sunriseTime.value.unix()) / (beforeSunrise.unix() - sunriseTime.value.unix());
        const sinCurve = Math.round(Math.sin(PI * percent) * 1000) / 1000;
        return `background: linear-gradient(
        300deg,
        #d4b0b5 ${30 * sinCurve}%,
        #ada6be,
        #527e99
    )`
    }else if(time.value.isBetween(beforeSunset, sunsetTime.value)) {
        //日の入り
        const percent = (time.value.unix() - sunsetTime.value.unix()) / (beforeSunset.unix() - sunsetTime.value.unix());
        const sinCurve = Math.round(Math.sin(PI * percent) * 1000) / 1000;
        return `background: linear-gradient(
        300deg,
        #C9CECA ${30 * sinCurve}%,
        #F3DFAC,
        #EEA66B
    )`
    }else if(isSunRise.value) {
        // ひる
        return `
            background: linear-gradient(
                to bottom,
                #527e99,
                #88a5b7,
                #acbdc7
            )
        `;
    }else {
        // よる
        return `
            background: linear-gradient(
                to bottom,
                #051637,
                #1c2c52,
                #5c658b
            )
        `;
    }
});
</script>

<style scoped>
.privateHeader {
    position: relative;
    overflow: hidden;
}

.clock {
    display: block;
    position: absolute;
    text-align: right;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    user-select: none;
}
.isDevCommander{
    position: absolute;
    left:  30px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>