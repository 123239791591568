<template>
    <div>
        <h2>再生リスト</h2>
        <p>再生リストを作成することができます。</p>
        <br />
        <p><a class="btn" @click="isRegisting = !isRegisting">新規登録</a></p>

        <playlistRegistField v-if="isRegisting" :key="updateContent" @change="reload" :updateContent_="updateContent"
            @close="updateContent = null; isRegisting = false" />

        <br />
        <hr />
        <br />
        <table class="normal w100">
            <tr>
                <th rowspan="2"></th>
                <th rowspan="2">サムネイル</th>
                <th rowspan="2">タイトル</th>
                <th rowspan="2">説明</th>
                <th rowspan="2">曲数</th>
                <th>登録日</th>
                <th rowspan="2">購読</th>
                <th rowspan="2"></th>
            </tr>
            <tr>
                <th>更新日</th>
            </tr>
            <tr v-if="playlists.length == 0">
                <td colspan="10">登録されている再生リストが存在しません</td>
            </tr>
            <template v-else>
                <tr>
                    <td rowspan="2"></td>
                    <td rowspan="2">規定</td>
                    <td rowspan="2">ユーザの再生リスト</td>
                    <td rowspan="2">デフォルトのユーザ</td>
                    <td rowspan="2">0</td>
                    <td>規定</td>
                    <td rowspan="2"><ToggleSwitch v-model="subscMusics" @change="subscTotal" /></td>
                    <td rowspan="2"></td>
                </tr>
                <tr>
                    <td>規定</td>
                </tr>
                <template v-for="list in playlists" :key="list">
                    <tr>
                        <td rowspan="2" :style="{ backgroundColor: list.color }">&nbsp;</td>
                        <td rowspan="2"><img class="thumb" :src="list.image" /></td>
                        <td rowspan="2">{{ list.title }}</td>
                        <td rowspan="2">{{ list.description || "説明文無し" }}</td>
                        <td rowspan="2">{{ list.cnt || 0 }}</td>
                        <td>{{ formatDate(list.insertAt, "YYYY年MM月DD日HH:mm:ss", "日付データがありません") }}</td>
                        <td rowspan="2">
                            <ToggleSwitch v-model="list.subscribe" @change="toggleSubsc(list)"></ToggleSwitch>
                        </td>
                        <td rowspan="2">
                            <p><a class="btn" @click="updateContent = list; isRegisting = true">更新</a></p>
                            <p><a class="btn" @click="select(list.id)">編集</a></p>
                            <p><a class="btn" @click="deletePlaylist(list)">削除</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ formatDate(list.updateAt, "YYYY年MM月DD日HH:mm:ss", "日付データがありません") }}</td>
                    </tr>
                </template>
            </template>
        </table>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import playlistRegistField from '@/components/items/playlistRegistField.vue'
import { formatDate } from '@/utils/utils';
import ToggleSwitch from '../items/toggleSwitch.vue';
import moment from 'moment';

const store = useStore();
const playlists = ref([]);
const isRegisting = ref(false);
const updateContent = ref(null);
const subscMusics = ref(false);
const subscMusicsCnt = ref(0);

// const filePath = ref(null);
const emit = defineEmits(['select']);

const load = async () => {
    playlists.value = await store.dispatch('Api/callApi', {
        url: "private/playlists"
    })
    subscMusicsCnt.value = await store.dispatch('Api/callApi', {
        url: "private/subscribing"
    })
    if(subscMusicsCnt.value) {
        // < 1
        subscMusics.value = false;
    }else {
        subscMusics.value = true;
    }
}
const reload = () => {
    location.reload();
}

const subscTotal = async () => {
    if(subscMusicsCnt.value == 0) {
        await store.dispatch('Common/showModal', { text: "購読中の再生リストが存在しない場合、この再生リストの購読は解除できません" })
        subscMusics.value = true;
        return;
    }
    await store.dispatch('Api/callApi', {
        url: "private/toggleDefaultPlaylists",
        data: {
            value: subscMusics.value
        }
    })
    await load();
}

const toggleSubsc = async (data_) => {
    const data = data_;

    if(!data.cnt || data.cnt == 0) {
        await store.dispatch('Common/showModal', { text: "曲が登録されていない再生リストを購読することはできません" })
        data_.subscribe = false;
        return;
    }

    data.insertAt = moment(new Date(data.insertAt)).format('YYYY-MM-DD HH:mm:ss');
    if (data.updateAt) data.updateAt = moment(new Date(data.updateAt)).format('YYYY-MM-DD HH:mm:ss');

    const resp = await store.dispatch('Api/callApi', {
        url: "private/registplaylists", data: {
            data
        },
        loading: false
    });
    if (!resp) {
        await store.dispatch('Common/showModal', { text: "購読情報の更新に失敗しました" })
    }
    await load();
}

const deletePlaylist = async (content) => {
    if (await store.dispatch('Common/openConfirm', {
        text: `「${content.title}」を削除します。よろしいですか？<br />削除すると元には戻せません`
    })) {
        const resp = await store.dispatch('Api/callApi', {
            url: "private/removePlaylist", data: {
                id: content.id
            }
        });
        if (resp) {
            await store.dispatch('Common/showModal', { text: "再生リストを削除しました。" })
        } else {
            await store.dispatch('Common/showModal', { text: "再生リストの削除に失敗しました" })
        }
        await load();
    }
}

const select = (id) => {
    emit('select', id)
}

load();
</script>

<style scoped>
.thumb {
    width: 100px;
}
</style>

