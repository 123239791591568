import { createRouter, createWebHistory } from 'vue-router'
import loginPage from '../views/loginPage.vue'
import makeAccounts from "../views/makeAccounts.vue"
import privatePage from "../views/privatePage.vue"
import resetPass from '../views/resetPass.vue'
import quizPage from '../views/quizPage.vue'
import spPrivate from '../views/sp/spPrivate.vue'
import videoLogger from "../views/videoLogger.vue"
import subscMessage from '@/views/subscMessage.vue'

const DEFAULT_TITLE = 'NiceMusic♪ Webコンソール'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: DEFAULT_TITLE + " ログイン" },
    component: loginPage
  },
  {
    path: "/verify/:id",
    name: "verify",
    meta: { title:DEFAULT_TITLE +  " 認証" },
    component: makeAccounts
  },
  {
    path: "/reset/:id",
    name: "reset",
    meta: { title: DEFAULT_TITLE + " パスワードリセット" },
    component: resetPass
  },
  {
    path: "/u",
    name: "user",
    meta: { title: DEFAULT_TITLE + "" },
    component: privatePage
  },
  {
    path: "/quiz",
    name: "quiz",
    meta: { title: "クイズジェネレーター" },
    component: quizPage
  },
  {
    path: "/sp",
    name: "sp",
    meta: { title: DEFAULT_TITLE + "" },
    component: spPrivate
  },
  {
    path: "/vl",
    name: "videoLogger",
    meta: {title: "ビデオロガー"},
    component: videoLogger
  },
  {
    path: "/sbsc/:id",
    name: "subscribeMessage",
    meta: { title: `${DEFAULT_TITLE} メッセージ購読`},
    component: subscMessage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  const title = to.meta.title ? to.meta.title || DEFAULT_TITLE : DEFAULT_TITLE;
  document.title = title;
})

export default router
