<template>
    <div class="sleepModeVue">
        <div class="sleepModeContents">
            <h2>再認証</h2>
            <p>しばらく操作がなかったためロックされました。再認証をしてください。</p>
            <hr />
            <p><br /></p>
            <p>ログイン用ID<br /><input type="text" v-model="username" /></p>
            <p>パスワード</p>
            <div class="passwordInput">
                <input :type="isShow ? 'text' : 'password'" v-model="password" @keydown.enter="login" />
                <img v-if="!isShow" class="passwordShower" src="@/assets/eye.png" title="パスワードを表示" @click="isShow = !isShow" />
                <img v-else class="passwordShower" src="@/assets/eye_not.png" title="パスワードを非表示" @click="isShow = !isShow" />
            </div>
            <p><br /></p>
            <hr />
            <p><br /></p>
            <p><a class="btn" @click="login">再認証</a></p>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    props: {
        modelValue: {Boolean}
    },
    setup(props, context) {
        const store = useStore();
        const username = ref();
        const password = ref();
        const isShow = ref(false);

        const login = async () => {
            const errors = [];
            if(!username.value) errors.push("ログイン用IDは必須です。");
            if(!password.value) errors.push("パスワードは必須です。");

            if(errors.length) {
                store.dispatch('Common/showModal', {text: errors.join('<br />')})
            }

            const resp = await store.dispatch('Api/callApi', {url: "private/unlock", data: {pass: password.value, name: username.value}})

            if(resp.result) {
                context.emit("update:modelValue", false);
            }else {
                store.dispatch('Common/showModal', {text: resp.message});
            }
        }

        return {
            username,
            password,
            isShow,
            login
        };
    },
}
</script>

<style scoped>
.sleepModeVue{
    width: 100%; height: 100%;
    background-color: #1e2124;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.sleepModeContents{
    width: 50%;
    background-color: #424549;
    border-radius: 30px;
    padding: 30px;
    color: white;
    text-align: center;
    font-size: large;
}
input{
    font-size: large !important;
    text-align: center !important;
}

.passwordInput{
    position: relative;
    display: inline-block;
}
.passwordShower{
    position: absolute;
    height: 28px;
    right: 10px;
    cursor: pointer;
    user-select: none;
}
.btn{
    color: black;
}
</style>