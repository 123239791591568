<template>
    <div class="spSearchArea">
        <p @click="isOpen = !isOpen"><a class="plusBtn">{{ isOpen ? "-" : "+" }}</a>絞り込み条件</p>
        <div class="spSearch fadeIn" v-if="isOpen">
            <div class="spSearchRow">
                <p v-if="value.count != undefined">表示件数: <select v-model="value.count">
                    <option value="10">10件</option>
                    <option value="50">50件</option>
                </select></p>
                <p v-if="value.title != undefined">タイトル検索: <input type="text" v-model="value.title" placeholder="タイトル" /></p>
                <p v-if="value.sort != undefined && value.sort.type != undefined && value.sort.order != undefined">ソート順: 
                    <select v-model="value.sort.type">
                        <option value="0">登録日</option>
                        <option value="1">週間再生回数</option>
                        <option value="2">全期再生回数</option>
                        <option value="3">再生順</option>
                        <option value="4">投稿者別</option>
                    </select>
                    <select v-model="value.sort.order">
                        <option value="0">昇順</option>
                        <option value="1">降順</option>
                    </select>
                </p>
            </div>
            <div class="spSearchRow">
                <p v-if="value.author != undefined">投稿者: <input type="text" v-model="value.author" placeholder="投稿者名" /></p>
                <p v-if="value.priority != undefined"><label><input type="checkbox" v-model="value.priority" />優先動画のみ</label></p>
            </div>
            <!-- <br /> -->
            <!-- <p><a class="spBtn" @click="emitValue">反映</a></p> -->
        </div>
    </div>
</template>
<script>
import { ref, computed, watch } from 'vue';

export default {
    props: {
        modelValue: {type: Object }
    },
    setup(props, context){
        const isOpen = ref(false);
        const value = ref({});

        const compModelValue = computed(() => {
            return props.modelValue;
        })
        watch(compModelValue, (v) => {
            value.value = v;
        })

        const load = () => {
            value.value = props.modelValue
        }
        load();

        const emitValue = () => {
            context.emit('update:modelValue', value);
            context.emit('change');
            isOpen.value = false;
        }
        return {
            isOpen,
            value,
            emitValue
        }
    }
}
</script>
<style scoped>
.plusBtn{
    width: 2em; height: 2em; 
    font-weight: bold;
    color: black;
    border: 3px solid black;
    background-color: white;
    margin: 0.5em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.spSearch{
    width: calc(100% - 20px);
    margin-bottom: 10px;
    background-color: #6d6d6d;
    padding: 10px;
    border-radius: 10px;
}
.spSearchRow{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>