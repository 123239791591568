<template>
    <tr>
        <td @click="modal = true"><img class="thumbImg" :src="video.thumbnails" /></td>
        <td class="titleText" @click="modal = true">
            <p>{{ video.title }}</p>
            <hr/>
            <p>{{ video.author.name }}</p>
        </td>
        <td><button class="blockButton tableChangeBtn" :class="{added: status}" @click="toggleFav">{{status ? "解除" : "ブロック"}}</button></td>
    </tr>
    <SpMusicModal v-if="modal" :video="video" @close="modal=false" @priorityUpdate="load"></SpMusicModal>
</template>
<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import SpMusicModal from '../item/spMusicModal.vue';

export default {
    props: {
        video: Object,
        stdev: Number
    },
    setup(props) {
        const store = useStore();
        const status = ref();
        const priority = ref();
        const modal = ref(false);
        const deviation = ref(null);
        const load = async () => {
            let st = await store.dispatch("Api/callApi", { url: "private/userMusicStatus", data: { url: props.video.url } });
            status.value = st.blocked;
            priority.value = st.priority;
            if (props.stdev)
                deviation.value = await store.dispatch("Api/callApi", { url: "private/calcDeviation", data: { url: props.video.url, stdev: props.stdev } });
        };
        load();
        const stdev_ = computed(() => {
            return props.stdev;
        });
        watch(stdev_, async () => {
            deviation.value = await store.dispatch("Api/callApi", { url: "private/calcDeviation", data: { url: props.video.url, stdev: props.stdev } });
        });
        const toggleFav = async () => {
            let url = props.video.url;
            await store.dispatch("Api/callApi", { url: "private/changeMusicStatus", data: { url, kind: "block", length: props.video.length } });
            let st = await store.dispatch("Api/callApi", { url: "private/userMusicStatus", data: { url: props.video.url } });
            status.value = st.favorite;
        };
        return {
            status,
            toggleFav,
            modal,
            priority,
            load,
            deviation
        };
    },
    components: { SpMusicModal }
}
</script>
<style scoped>
.thumbImg{
    width: 100px;
}
.titleText p{
    width: 130px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
</style>