<template>
    <div>
        <div class="topRanking" v-if="recommend.length">
            <h2>他の人はこんな曲も聴いています!</h2>
            <imageScrollVue :kind="'recomend'" :images="recommend"></imageScrollVue>
        </div>
        <div class="topRanking" v-if="famousAuthors && famousAuthors.length">
            <h2>以下のチャンネルが人気です</h2>
            <ChannelsIcons :list="famousAuthors"></ChannelsIcons>
        </div>
        <div class="latestInfo" v-if="latestInfo && nowMyCnt">
            <h2><u>現在の情報</u></h2>
            <p>登録曲数(実質): <u>{{latestInfo.realCnt}}曲</u></p>
            <p>登録曲数(名目): <u>{{latestInfo.allCnt}}曲</u></p>
            <p>あなたの登録曲数: <u>{{latestInfo.mineCnt}}曲</u></p>
            <p>平均: <u>{{ Math.floor(avg * 100) / 100 }}回</u>,標準偏差: <u>{{ Math.floor(stdev * 100) / 100 }}</u></p>
            <p>今期累計再生回数(自分): <u>{{nowMyCnt.current}}回/ {{nowMyCnt.played}}回</u>(全期)</p>
            <p>累計再生時間(自分): <u>{{totalLength_mine_Comp}}</u></p>
            <p>今期累計再生回数(全体): <u>{{latestInfo.totalView.current}}回/ {{latestInfo.totalView.played}}回</u>(全期)</p>
            <p>累計再生時間(全体): <u>{{totalLengthComp}}</u></p>
        </div>
        <div class="topRanking" v-if="latestListening.length">
            <h2>最近聴かれた曲 5曲</h2>
            <imageScrollVue :kind="'latest'" :images="latestListening"></imageScrollVue>
        </div>
        <div class="topRanking" v-if="latestListening.length">
            <h2>最近追加された曲 5曲</h2>
            <imageScrollVue :kind="'latest'" :images="latestMusics"></imageScrollVue>
        </div>
        <div class="topRanking" v-if="viewRanking.length">
            <h2>自分の再生回数(今週) TOP5</h2>
            <imageScrollVue :kind="'view'" :images="myTop" :mytop="true"></imageScrollVue>
        </div>
        <div class="topRanking" v-if="viewRanking.length">
            <h2>自分の再生回数(全期) TOP5</h2>
            <imageScrollVue :kind="'view'" :images="myTopAll" :mytop="true"></imageScrollVue>
        </div>
        <div class="topRanking" v-if="ranking.length">
            <h2>お気に入り件数 TOP5</h2>
            <imageScrollVue :kind="'favorite'" :images="ranking"></imageScrollVue>
        </div>
        <div class="topRanking" v-if="viewRanking.length">
            <h2>今週聞かれた曲(全体) TOP5</h2>
            <imageScrollVue  :images="thisWeek"></imageScrollVue>
        </div>
        <div class="topRanking" v-if="viewRanking.length">
            <h2>再生回数(全体) TOP5</h2>
            <imageScrollVue :kind="'view'" :images="viewRanking"></imageScrollVue>
        </div>
    </div>
</template>
<script>
import { onUnmounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import imageScrollVue from '@/components/items/imageScroll.vue';
import { subscFormat } from '@/utils/utils';
import {} from '../../utils/utils';
import ChannelsIcons from '../items/channelsIcons.vue';

export default {
    components: {
    imageScrollVue,
    ChannelsIcons
},
    setup(){
        const store = useStore();
        const ranking = ref([]);
        const viewRanking = ref([]);
        const latestListening = ref([]);
        const recommend = ref([]);
        const latestInfo = ref();
        const recomendTimeout = ref(null);
        const myTop = ref([]);
        const myTopAll = ref([]);
        const thisWeek = ref([]);
        const totalLength = ref();
        const nowMyCnt = ref();
        const stdev = ref();
        const avg = ref();
        const famousAuthors = ref();
        const latestMusics = ref([]);

        const recomendLoad = async () => {
            latestInfo.value = await store.dispatch('Api/callApi', {url: 'private/latestInfo'});
            recommend.value = await store.dispatch('Api/callApi', {url: "private/reccomand"});
            latestListening.value = await store.dispatch('Api/callApi', {url: "private/latestListening"});
            totalLength.value = await store.dispatch('Api/callApi', {url: "private/getTotalLength"});
            nowMyCnt.value = await store.dispatch('Api/callApi', {url: "private/myPlayCnt"});
            let rsp = await store.dispatch('Api/callApi', {url: "private/calcSTDEV"});
            stdev.value = rsp.stdev;
            avg.value = rsp.avg;
            
        }

        const load = async () => {
            ranking.value = await store.dispatch('Api/callApi', {url: "private/ranking"});
            viewRanking.value = await store.dispatch('Api/callApi', {url: "private/viewRanking"});
            myTop.value = await store.dispatch('Api/callApi', {url: "private/getMyMusicCnt"});
            myTopAll.value = await store.dispatch('Api/callApi', {url: "private/getMyMusicCntAll"});
            thisWeek.value = await store.dispatch('Api/callApi', {url: "private/getThisWeek"});
            famousAuthors.value = await store.dispatch('Api/callApi', {url: "private/getFamousAuthor", data: {
                type: 0
            }})
            latestMusics.value = await store.dispatch('Api/callApi', {url: "private/latestSongLists"});
            await recomendLoad();

            recomendTimeout.value = setInterval(recomendLoad, 20000);
        }
        load();

        onUnmounted(() => {
            if(recomendTimeout.value){
                clearInterval(recomendTimeout.value);
            }
        })

        const totalLengthComp = computed(() => {
            if(!totalLength.value) return "取得中";

            let text = totalLength.value;
            let current = text.current;
            let played = text.played;

            return `${current.days}日${current.hour}時間${(current.minute)}分 / ${played.days}日${played.hour}時間${played.minute}分(全期)`;
        })

        const totalLength_mine_Comp = computed(() => {
            if(!nowMyCnt.value) return "取得中";

            let current = nowMyCnt.value.current_time;
            let played = nowMyCnt.value.played_time;

            return `${current.days}日${current.hour}時間${current.minute}分 / ${played.days}日${played.hour}時間${played.minute}分(全期)`;
        })

        return {
            ranking,
            viewRanking,
            latestListening,
            recommend,
            subscFormat,
            latestInfo,
            myTop,
            myTopAll,
            thisWeek,
            totalLengthComp,
            nowMyCnt,
            totalLength_mine_Comp,
            stdev,
            avg,
            famousAuthors,
            latestMusics
        }
    }
}
</script>