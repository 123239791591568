<template>
    <div>
        <div>
            <h2>ブロックリスト</h2>
            <p>現在登録されている動画が表示されます。<br />詳細情報が取得されていない動画は表示されません。(反映に時間がかかる場合があります。)</p>
            <br />
            <p>動画をブロック: <input type="url" placeholder="https://youtu.be/xxxxxx" v-model="url" />&nbsp;<a class="btn" @click="regist">ブロック</a></p>
            <br />
            <p>表示件数: <select v-model="viewMax">
                <option value="10">10件</option>
                <option value="50">50件</option>
            </select></p>
        </div>
        <pageNation
            v-model="current"
            :maxPage="maxPage"
            :keyObserb="true"
            :viewMax="viewMax"
        ></pageNation>
        <table class="normal w100">
            <tr>
                <th class="thumbnailsFav">サムネイル</th>
                <th>タイトル</th>
                <th>投稿者</th>
                <th></th>
            </tr>
            <!-- <tr v-for="vl in viewList" :key="vl" class="favRow">
                <td class="thumbnailsFav"><img :src="vl.thumbnails"/></td>
                <td>{{vl.title}}</td>
                <td>{{vl.author.name}}</td>
                <td><button class="favoButton tableChangeBtn">登録</button></td>
            </tr> -->
            <blkRowVue v-for="vl in viewList" :key="vl" :vl="vl"></blkRowVue>
            <tr v-if="favos.length == 0">
                <td colspan="5" class="center">ブロックされている曲がありません。</td>
            </tr>
        </table>
        <pageNation
            v-model="current"
            :maxPage="maxPage"
            :viewMax="viewMax"
        ></pageNation>
    </div>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import blkRowVue from './rows/blkRow.vue';
import pageNation from '../items/pageNation.vue';

export default {
    components: {
        blkRowVue,
        pageNation
    },
    setup(){
        const store = useStore();
        const favos = ref([]);
        const url = ref("");
        const current = ref(1);
        
        const viewMax = ref("10");
        
        const maxComp = computed(() => {
            return favos.value.length
        })

        const min = ref(0);
        const load = async () => {
            favos.value = await store.dispatch('Api/callApi', {url: 'private/blocked'});
        }
        load();

        const viewList = computed(() => {
            let arry = [];
            let end = current.value * viewMax.value;
            let start = end - viewMax.value;
            // console.log(start, end);
            arry = favos.value.slice(start, end);
            return arry;
        });

        const regist = async () => {
            //inputChangeStatus
            let uu = url.value;
            url.value = "";
            let rslt = await store.dispatch('Api/callApi', {url: "private/inputChangeStatus", data: {url: uu, kind: "block"}});
            if(rslt){
                await store.dispatch('Common/showModal', {text: "登録しました。"});
                load();
            }else{
                await store.dispatch('Common/showModal', {text: "登録に失敗しました。"});
            }
            
        }

        const openURL = async (url) => {
            const cnf = await store.dispatch('Common/openConfirm', {header: "外部のページ", text: `外部のページに飛びます。<br />よろしいですか？<br />URL: ${url}`});
            if (cnf) {
                let a = document.createElement("a");
                a.href = url;
                a.target = "_blank";
                a.click();
                a.remove();
            }
        }

        const maxPage = computed(() => {
            return Math.ceil(favos.value.length / viewMax.value);
        })

        return {
            favos,
            viewList,
            min,
            viewMax,
            maxComp,
            regist,
            url,
            openURL,
            current,
            maxPage
        }
    }
}
</script>