<template>
    <div v-if="userData" class="usersIconVue" :style="{width: `${size}px`, height: `${size}px`}" :title="userData.name">
        <img :src="userData.avatar || userData.defaultImage" />
    </div>
</template>

<style scoped>
.usersIconVue {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s;
    margin: 0.2em;
}
.usersIconVue:hover{
    transform: scale(110%);
    transition: 0.5s;
}
.usersIconVue img{
    width: 100%; height: 100%;
}
</style>

<script>
export default {
    props: {
        userData: Object,
        size: {type: Number, default: 60}
    },
    setup(){
    }
}
</script>