import axios from 'axios';
import createPersitstedState from 'vuex-persistedstate';
const state = ()=> ({
    isProd: false
});
const mutations = {
    toggleIsProd(state, value) {
        state.isProd = value;
    }
};
const actions = {
    async callApi(context, obj){
        let token;
        const data = (obj.data) ? obj.data : undefined;
        const is_loading = obj.loading != false ? true : false;
        if(is_loading) token = await context.dispatch('Common/addLoadingContent', null, {root: true});
        return new Promise((resolve, reject) => {
            axios({
                method: "POST",
                url: targetURL(context.state) + obj.url,
                data,
                withCredentials: true,
            }).then(data => {
                resolve(data.data);
            }).catch(e => {
                console.error(e);
                if(e.response.status == 401){
                    context.commit('Common/showModal', {text: 'セッションが切れました。\n再度ログインをしてください。', move: true}, {root: true});
                }else if(e.response.data){
                    //console.log(e.response.statusText);
                    context.commit('Common/showModal', {text: e.response.data.error || e.response.statusText, move: false}, {root: true});
                }
                reject(e);
            }).finally(() => {
                context.dispatch('Common/removeLoadingContent', token,  {root: true});
            })
        });
    },
    async callNormalApi(context, obj){
        let token = await context.dispatch('Common/addLoadingContent', null, {root: true});
        // console.log(targetURL + obj.url)
        return new Promise((resolve, reject) => {
            axios({
                method: "POST",
                url: targetURL(context.state) + obj.url,
                data: obj.data,
                withCredentials: true,
            }).then(data => {
                // console.log(data);
                resolve(data.data);
            }).catch(e => {
                // console.log(e);
                context.dispatch('Common/showModal', {text: e.response.data.error || e.response.statusText, move: true},  {root: true});
                reject(e);
            })
            .finally(() => {
                context.dispatch('Common/removeLoadingContent', token,  {root: true});
            })
        })
    },
    async Login(context, obj){
        let token = await context.dispatch('Common/addLoadingContent', null, {root: true});
        // console.log(targetURL + obj.url)
        return new Promise((resolve, reject) => {
            axios({
                method: "POST",
                url: targetURL(context.state) + obj.url,
                data: obj.data,
                withCredentials: true,
            }).then(data => {
                resolve(data.data);
            }).catch(e => {
                // console.log(e.response);
                if(e.response.status == 401){
                    context.dispatch('Common/showModal', {text: "認証に失敗しました", move: true},  {root: true});
                }else{
                    context.dispatch('Common/showModal', {text: e.response.data.error || e.response.statusText, move: true},  {root: true});
                }
                
                reject(e);
            })
            .finally(() => {
                context.dispatch('Common/removeLoadingContent', token,  {root: true});
            })
        })
    },
    async loginCheck(context){
        let token = await context.dispatch('Common/addLoadingContent', null, {root: true});
        return new Promise((resolve) => {
            axios({
                method: "POST",
                url: targetURL(context.state) + "private/",
                // data: obj.data,
                withCredentials: true,
            }).then(data => {
                resolve(data.data);
            }).catch(e => {
                console.log(e.response);
                resolve(false);
            })
            .finally(() => {
                context.dispatch('Common/removeLoadingContent', token,  {root: true});
            })
        })
    },
    async logout(context){
        let token = await context.dispatch('Common/addLoadingContent', null, {root: true});
        return new Promise((resolve, reject) => {
            axios({
                method: "POST",
                url: targetURL(context.state) + "private/logout",
                // data: obj.data,
                withCredentials: true,
            }).then(data => {
                resolve(data.data);
            }).catch(e => {
                console.error(e);
                if(e.response.status == 401){
                    context.commit('Common/showModal', {text: 'セッションが切れました。\n再度ログインをしてください。', move: true}, {root: true});
                }else if(e.response.data){
                    //console.log(e.response.statusText);
                    context.commit('Common/showModal', {text: e.response.data.error || e.response.statusText, move: false}, {root: true});
                }
                reject(e);
            })
            .finally(() => {
                context.dispatch('Common/removeLoadingContent', token,  {root: true});
            })
        })
    },
    async callOtherApi(context, obj){
        let token = await context.dispatch('Common/addLoadingContent', null, {root: true});
        const data = (obj.data) ? obj.data : undefined;
        return new Promise((resolve, reject) => {
            axios({
                method: "POST",
                url: obj.url,
                data,
            }).then(data => {
                resolve(data.data);
            }).catch(e => {
                console.error(e);
                if(e.response.data){
                    //console.log(e.response.statusText);
                    context.commit('Common/showModal', {text: e.response.data.error || e.response.statusText, move: false}, {root: true});
                }
                reject(e);
            }).finally(() => {
                context.dispatch('Common/removeLoadingContent', token,  {root: true});
            })
        });
    },
    async toggleIsProd(context, value) {
        if(process.env.NODE_ENV != 'development') {
            await context.dispatch('Common/showModal', {
                text: "この機能は開発環境でのみ使用できます"
            }, {root: true});
            return;
        }else{
            context.commit('toggleIsProd', value);
        }
    }
};
const getters = {
    isProd: state => {return (process.env.NODE_ENV == 'development') ? state.isProd : false},
    targetURL: state => {return targetURL(state)}
};

// const targetURL = (process.env.NODE_ENV == 'development') ? 'http://localhost:3005/' : "https://asogi.net:3005/";
const prodUrl = "https://asogi.net/api/";
const devUrl = 'http://localhost:3005/';
function targetURL(state) {
    if((process.env.NODE_ENV != 'development')) return prodUrl;
    const isProd = state.isProd;

    return isProd ? prodUrl : devUrl;
    
}

export default{
    namespaced: true,
    state: state(),
    mutations,
    actions,
    getters,
    plugins: [createPersitstedState({
        strage: window.sessionStorage
    })]
}

