<template>
    <div class="privateContent">
        <table class="normal w100">
            <tr>
                <th rowspan="4">パスワード変更</th>
                <th>現在のパスワード</th>
                <td><input type="password" name="password" v-model="pass1" /></td>
            </tr>
            <tr>
                <th>パスワード</th>
                <td><input type="password" name="password2" v-model="pass2" /></td>
            </tr>
            <tr>
                <th>確認</th>
                <td><input type="password" name="password3" v-model="pass3" /></td>
            </tr>
            <tr>
                <td colspan="2"><a class="btn" @click="changePass">送信</a></td>
            </tr>
            <tr>
                <th>お気に入り公開設定</th>
                <td colspan="2">
                    <ToggleSwitch @change="reccomendSettingUpdate" v-model="recommendSetting"
                        :label="`${recommendSetting ? '' : '非'}公開`"></ToggleSwitch>
                </td>
            </tr>
            <tr>
                <th rowspan="2">選曲モード1</th>
                <th>アルゴリズム自動選択</th>
                <td>
                    <ToggleSwitch v-model="isAutoAlgos" :label="isAutoAlgos ? 'ON' : 'OFF'" @change="autoAlgosChange">
                    </ToggleSwitch>
                </td>
            </tr>
            <tr>
                <th>再生回数順有無</th>
                <td>
                    <select class="w100 h100" style="text-align: center; font-size: large;"
                        @change="autoAlgosSearchUpdate" v-model="autoAlgosSearch">
                        <option value="0">無</option>
                        <option value="1">有</option>
                        <option value="2">有(完全含)</option>
                    </select>
                </td>
            </tr>
            <tr>
                <th>選曲モード2</th>
                <!-- <td colspan="2"><ToggleSwitch @change="algosUpdate" v-model="algosValue" :label="`${algosValue ? '短期再生数' : '全期再生数'}モード`"></ToggleSwitch></td> -->
                <td colspan="2"><select class="w100 h100" style="text-align: center; font-size: large;"
                        @change="algosUpdate" v-model="algosValue">
                        <option value="0">全期再生回数</option>
                        <option value="1">短期再生回数</option>
                        <option value="2">再生回数順</option>
                        <option value="4">完全再生回数順</option>
                        <option value="3">ランダム</option>
                    </select></td>
            </tr>
            <template v-if="browse == 3 || browse == 5">
                <tr>
                    <th rowspan="3">外部ツール設定</th>
                    <th>インストール</th>
                    <td v-if="browse == 3">インストールは <a
                            href="https://chrome.google.com/webstore/detail/nicemusic%E2%99%AA-%E3%82%A2%E3%83%89%E3%82%AA%E3%83%B3/agnokkgignceolngcmoebenlnogpikhp?hl=ja&authuser=0"
                            target="_blank" title="NiceMusic♪ アドオン">こちら(chrome)</a></td>
                    <td v-if="browse == 5">準備中</td>
                </tr>
                <tr>
                    <td colspan="2">YouTubeのページから直接お気に入り登録や予約ができます。</td>
                </tr>
                <tr>
                    <th>このブラウザを登録</th>
                    <td><a class="btn" @click="registBrowser">登録</a></td>
                </tr>
                <tr>
                    <th :rowspan="dbFprints.length + 1">登録中のブラウザ</th>
                    <td v-if="dbFprints.length == 0" colspan="2">登録されているブラウザはありません</td>
                    <template v-else>
                        <th>登録名</th>
                        <th></th>
                    </template>
                </tr>
                <tr v-for="fp in dbFprints" :key="fp">
                    <td>{{ fp.name }}<span v-if="fp.id == fingerprint"> (このブラウザ)</span></td>
                    <td><a class="btn" @click="removeFp(fp.id)">登録削除</a></td>
                </tr>
            </template>
            <tr v-if="admin">
                <th>更新</th>
                <td colspan="2">
                    <a class="btn" @click="update">更新</a>
                </td>
            </tr>
            <tr v-if="admin">
                <th>シャットダウン(再起動)</th>
                <td colspan="2">
                    <a class="btn" @click="shutdown">シャットダウン(再起動)</a>
                </td>
            </tr>
            <tr v-if="admin">
                <th>曲更新</th>
                <td colspan="2">
                    <a class="btn" @click="updateMusics">曲更新</a>
                </td>
            </tr>

            <template v-if="admin">
                <!-- クエリ -->
                <tr>
                    <th :rowspan="5 + queryValues.length">クエリ</th>
                    <th>SQL</th>
                    <td>
                        <textarea v-model="sqlQuery" class="sqlQuery" rows="10"></textarea>
                    </td>
                </tr>
                <tr>
                    <th>値追加</th>
                    <td><input type="text" title="Press Enter Add" v-model="addValueQuery"
                            @keydown.enter="addValueQueryFnc()" /></td>
                </tr>
                <tr v-for="qv in queryValues" :key="qv">
                    <th><input type="number" v-model="qv.number" /></th>
                    <td><input type="text" v-model="qv.value" /></td>
                </tr>
                <tr>
                    <th></th>
                    <td><a class="btn" @click="executeQuery">実行</a></td>
                </tr>
                <tr>
                    <th>テンプレート</th>
                    <td><a class="btn" @click="currentReset">短期リセット</a><a class="btn" @click="playedSet">長期セット</a></td>
                </tr>
                <tr>
                    <th>結果</th>
                    <td>
                        <p v-html="sqlResult" class="sqlResult"></p>
                    </td>
                </tr>
            </template>
        </table>
        <br />

        <template v-if="admin">
            <h2>ユーザ一覧</h2>
            <pageNationVue :maxPage="maxPageUD" v-model="currentUD" :keyObserb="true" :viewMax="viewMax">
            </pageNationVue>
            <table class="normal w100">
                <tr>
                    <th>ID</th>
                    <th>アイコン</th>
                    <th>名前</th>
                    <th>登録状況</th>
                    <th>購読情報</th>
                    <th colspan="2">設定</th>
                    <th colspan="2">楽曲情報</th>
                    <th colspan="2">日時情報1</th>
                    <th colspan="2">日時情報2</th>
                </tr>
                <userDataVue v-for="ul in UDView" :key="ul" :data="ul"></userDataVue>
            </table>
        </template>
        <br />
        <h2>ログイン歴</h2>
        <pageNationVue :maxPage="maxPage" v-model="current" :viewMax="viewMax"></pageNationVue>
        <table class="normal w100">
            <tr>
                <th rowspan="2">現在の情報</th>
                <th>IPアドレス</th>
                <td :colspan="admin ? 2 : 1">{{ nowIp }}</td>
            </tr>
            <tr>
                <th>端末情報</th>
                <td :colspan="admin ? 2 : 1">{{ nowAgent }}</td>
            </tr>
            <tr>
                <th v-if="admin">ユーザ</th>
                <th>日時</th>
                <th>IPアドレス</th>
                <th>端末情報</th>
            </tr>
            <tr v-for="h in history" :key="h">
                <td v-if="admin">{{ h.name }}</td>
                <td>{{ formatDate(h.insertAt) }}</td>
                <td>{{ h.IP }}</td>
                <td>{{ h.agent }}</td>
            </tr>
        </table>
    </div>
    <browseSettingVue v-if="modal" @close="modal = false" :fp="fingerprint" @registed="modal = false; getDBFprint();">
    </browseSettingVue>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import FingerprintJS from "@fingerprintjs/fingerprintjs"
import browseSettingVue from '../modals/browseSetting.vue';
import moment from 'moment';
import pageNationVue from '../items/pageNation.vue';
import ToggleSwitch from '../items/toggleSwitch.vue';
import userDataVue from '../items/userData.vue';

export default {
    components: {
        browseSettingVue,
        pageNationVue,
        ToggleSwitch,
        userDataVue
    },
    setup() {
        const store = useStore();
        const pass1 = ref("");
        const pass2 = ref("");
        const pass3 = ref("");
        const fingerprint = ref("");
        const modal = ref(false);
        const browseName = ref("");
        const dbFprints = ref([]);
        const browse = ref(false);

        const nowAgent = ref("");
        const nowIp = ref("");
        const history = ref([]);
        const admin = ref(false);
        const current = ref(1);
        const viewMax = 8;

        const recommendSetting = ref(false);
        const algosValue = ref(false);

        const checkAdmin = ref(false);
        const userList = ref([]);
        const currentUD = ref(1);

        const isAutoAlgos = ref(true);
        const autoAlgosSearch = ref();

        const sqlQuery = ref("");
        const addValueQuery = ref("");
        const queryValues = ref([]);
        const sqlResult = ref("");

        const parseUserValue = (userValue) => {
            // if(!userValue || userValue.algos === undefined || userValue.autoAlgos === undefined || userValue.autoAlgosSearch === undefined) throw new Error('アルゴリズムデータが不足しています')
            algosValue.value = userValue.algos;
            isAutoAlgos.value = userValue.autoAlgos ? true : false;
            autoAlgosSearch.value = userValue.autoAlgosSearch;
        }

        const load = async () => {
            const fpPromise = await FingerprintJS.load();

            const result = await fpPromise.get();
            fingerprint.value = result.visitorId;
            getDBFprint();
            browse.value = checkBrowse();

            let res = await store.dispatch('Api/callApi', { url: "private/getIpAddr" });
            nowIp.value = res.ip;
            nowAgent.value = res.agent;
            history.value = res.history;
            admin.value = res.admin

            recommendSetting.value = await store.dispatch('Api/callApi', { url: "private/getPublishFav" });
            let userValue = await store.dispatch('Api/callApi', { url: "private/algosSetting" });
            parseUserValue(userValue);

            if (admin.value) {
                //Admin
                userList.value = await store.dispatch('Api/callApi', { url: "private/getUserLists" })
            }
        }
        load();

        const changePass = async () => {
            let err = [];
            if (!pass1.value) err.push('現在のパスワードは必須です')
            if (!pass2.value) err.push('パスワードは必須です')
            if (!pass3.value) err.push('パスワード確認は必須です')
            if (pass2.value != pass3.value) err.push('パスワードと確認が一致していません')

            if (err.length) {
                await store.dispatch('Common/showModal', { text: err.join('<br />') });
                return;
            }

            let data = {
                oldPass: pass1.value,
                newPass: pass2.value
            }

            let r = await store.dispatch('Api/callApi', { url: "private/changePassword", data });

            if (r) {
                await store.dispatch('Common/showModal', { text: "変更しました。" });
            } else {
                await store.dispatch('Common/showModal', { text: "変更に失敗しました" });
            }
        }
        const registBrowser = async () => {
            modal.value = true;
        }

        const getDBFprint = async () => {
            dbFprints.value = await store.dispatch('Api/callApi', { url: "private/getFprints" });
        }

        const removeFp = async (id) => {
            let rsp = await store.dispatch('Api/callApi', { url: "private/removePrint", data: { id } });

            if (rsp) {
                await store.dispatch('Common/showModal', { text: "削除しました" });
                getDBFprint()
            } else {
                await store.dispatch('Common/showModal', { text: "削除に失敗しました。" });
            }
        }

        function checkBrowse() {
            let userAgent = window.navigator.userAgent.toLowerCase();

            if (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
                return 1
            } else if (userAgent.indexOf('edge') != -1) {
                return 2
            } else if (userAgent.indexOf('chrome') != -1) {
                return 3
            } else if (userAgent.indexOf('safari') != -1) {
                return 4
            } else if (userAgent.indexOf('firefox') != -1) {
                return 5
            } else if (userAgent.indexOf('opera') != -1) {
                return 6
            } else {
                return 0
            }
        }

        const formatDate = (date) => {
            let mom = moment(date);
            return mom.format('YYYY年MM月DD日 kk:mm:ss');
        }

        const maxPage = computed(() => {
            let mx = 0;

            mx = Math.ceil(history.value.length / viewMax);

            return mx;
        })

        const historyView = computed(() => {
            let end = current.value * viewMax;
            let start = end - viewMax;
            return history.value.slice(start, end);
        })

        const shutdown = async () => {
            let resp = await store.dispatch('Api/callApi', { url: "private/shutdown" });
            if (resp) {
                await store.dispatch('Common/showModal', { text: "シャットダウンしました。<br />再起動に時間がかかる場合があります" });
            }
        }

        const reccomendSettingUpdate = async () => {
            await store.dispatch('Api/callApi', { url: "private/changePublishFav", data: { fav: recommendSetting.value } });
        }

        const algosUpdate = async () => {
            // console.log(algosValue.value);
            let userValue = await store.dispatch('Api/callApi', { url: "private/algosChange", data: { value: algosValue.value } });
            parseUserValue(userValue);
        }

        const autoAlgosSearchUpdate = async () => {
            let userValue = await store.dispatch('Api/callApi', { url: "private/autoAlgosSearchUpdate", data: { value: autoAlgosSearch.value } });
            parseUserValue(userValue);
        }

        const maxPageUD = computed(() => {
            let mx = 0;

            mx = Math.ceil(userList.value.length / 5);

            return mx;
        })

        const UDView = computed(() => {
            let end = currentUD.value * 5;
            let start = end - 5;
            return userList.value.slice(start, end);
        })

        const autoAlgosChange = async () => {
            let userValue = await store.dispatch('Api/callApi', { url: "private/algoAutoChanger", data: { value: isAutoAlgos.value } });
            parseUserValue(userValue);
        }

        const updateMusics = async () => {
            const resp = await store.dispatch('Api/callApi', { url: "private/musicUpdator" });
            await store.dispatch('Common/showModal', { text: resp ? resp : "更新対象がありませんでした" })
        }

        const update = async () => {
            const resp = await store.dispatch('Api/callApi', { url: "private/checkUpdate" });

            if (resp.result) {
                const resp2 = await store.dispatch('Common/openConfirm', { header: "更新確認", text: `最新バージョンが公開されています。更新しますか？<br />バージョン: ${resp.obj}<br />更新時には再起動されます。` })

                if (resp2) {
                    const resp = await store.dispatch('Api/callApi', { url: "private/executeUpdate" });
                    if (resp) await store.dispatch('Common/showModal', { text: "更新が完了しました。<br />再起動に時間がかかる場合があります" });
                }
            } else {
                await store.dispatch('Common/showModal', { text: "更新データがありませんでした" });
            }
        }

        const addValueQueryFnc = () => {
            let max = 0;

            for (let qv of queryValues.value) {
                if (max < qv.number) max++;
            }

            queryValues.value.push({
                number: ++max,
                value: addValueQuery.value
            })

            queryValues.value = queryValues.value.sort((a, b) => { return a.number - b.number });

            addValueQuery.value = "";
        }

        const executeQuery = async () => {
            if (sqlQuery.value == "") {
                await store.dispatch('Common/showModal', { text: "SQLを入力してください" });
                return;
            }
            let placeholder_ = sqlQuery.value.match(/\$[0-9]+/g);
            let placeholder = Array.from(new Set(placeholder_));
            placeholder = placeholder.sort((a, b) => { return Number(a.replace('$', "")) - Number(b.replace('$', "")) });
            // console.log(placeholder);

            let array = [];
            if (placeholder.length) {
                if (Number(placeholder[0].replace("$", "")) < 1 || queryValues.value.length != Number(placeholder[placeholder.length - 1].replace("$", ""))) {
                    await store.dispatch('Common/showModal', { text: "プレースホルダの添字が不正です" });
                    return;
                }

                for (let p of placeholder) {
                    array.push(queryValues.value[Number(p.replace('$', "")) - 1].value);
                }
            }

            let data = {
                sql: sqlQuery.value,
                values: array
            }

            // console.log(data);

            const resp = await store.dispatch('Api/callApi', { url: "private/executeQuery", data });
            sqlResult.value = resp;
        }

        const currentReset = async () => {
            if (await store.dispatch('Common/openConfirm', { text: "短期再生回数をリセットします。<br />よろしいですか？" })) {
                const data = {
                    sql: "UPDATE musics SET current=0",
                    values: []
                }
                const resp = await store.dispatch('Api/callApi', { url: "private/executeQuery", data });
                sqlResult.value = resp;
            }
        }

        const playedSet = async () => {
            if(await store.dispatch('Common/openConfirm', { text: "現在入力されている値は削除されます。<br />よろしいですか？" })) {
                sqlQuery.value = `UPDATE musics SET played=$1, current=$2 WHERE userId=$3 AND played<$1 AND deleted=0
                `;
                queryValues.value = [
                    {number: 1, value: "再生回数"},
                    {number: 2, value: "0"},
                    {number: 3, value: "ユーザId"}
                ]
            }
        }

        const sqlResultComp = computed(() => {
            if(!sqlResult.value) return "";
            let text = sqlResult.value.replace(/,/g, ",<br />").replace(/\{/g, '{<br />').replace(/\}/g, "<br />}");
            return text;
        })

        return {
            changePass,
            pass1,
            pass2,
            pass3,
            registBrowser,
            fingerprint,
            modal,
            browseName,
            dbFprints,
            getDBFprint,
            removeFp,
            checkBrowse,
            browse,
            nowAgent,
            nowIp,
            formatDate,
            history: historyView,
            admin,
            maxPage,
            current,
            checkAdmin,
            shutdown,
            recommendSetting,
            reccomendSettingUpdate,
            algosValue,
            algosUpdate,
            userList,
            maxPageUD,
            currentUD,
            UDView,
            isAutoAlgos,
            autoAlgosChange,
            updateMusics,
            update,
            sqlQuery,
            addValueQuery,
            queryValues,
            addValueQueryFnc,
            executeQuery,
            sqlResult: sqlResultComp,
            autoAlgosSearch,
            autoAlgosSearchUpdate,
            currentReset,
            playedSet
        }
    }
}
</script>

<style scoped>
a {
    color: white;
}

.sqlQuery {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 10px;
    resize: none;
}
.sqlResult{
    padding: 20px;
    background-color: white;
    color: black;
    overflow: auto;
    text-align: left;

}
</style>