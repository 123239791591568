<template>
    <div class="spImagesField">
        <div class="images" :key="current" :class="{leftIn: current == 0 && previewList.length == 2, rightIn: current == 1 && previewList.length == 2}">
            <div class="image" v-for="v in previewList[current]" :key="v" @click="select(v)">
                <img :src="v.thumbnails" />
            </div>
        </div>

        <template v-if="previewList.length == 2">
            <div class="right slideButton fadeIn" v-if="current == 0" @click="current=1">
                <!-- right -->
                &gt;
            </div>
            <div class="left slideButton fadeIn" v-else @click="current=0">
                <!-- left -->
                &lt;
            </div>
        </template>
    </div>
</template>
<script>
import { computed, ref, watch } from 'vue'


export default {
    props: {
        videos: Array
    },
    setup(props, context) {
        const count = computed(() => {
            if(!props.videos) return 0;
            return props.videos.length;
        })
        watch(count, (v) => {
            if(v <= 3 && current.value != 0) current.value = 0;
        })
        const current = ref(0);
        const select = (v) => {
            context.emit("current", v)
        }
        const previewList = computed(() => {
            if(count.value <= 3) {
                return [props.videos];
            }else{
                return [props.videos.slice(0, 3), props.videos.slice(3)];
            }
        })
        return {
            select,
            count,
            previewList,
            current
        }
    }
}
</script>
<style scoped>
.spImagesField {
    width: 100%;
    position: relative;
}
.images{
    width: 100%;
    display: flex;
    justify-content: center;
}
.image{
    width: 28%;
}
.image img {
    width: 100%;
}
.slideButton{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5em; height: 4em;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    color:#36393e;
    font-weight: bold;
    font-family: "M PLUS 1p";
}
.slideButton.left {
    left: 0px
}
.slideButton.right{
    right: 0px;
}
</style>