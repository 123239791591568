import moment from "moment";

export function subscFormat(num2){
    if(isNaN(num2)) return "取得失敗";
    let n = "";
    let num = Number(num2);
    if(100000000 <= num){
        n += (Math.round(num / 1000000) / 100) + "億";
    }else if(1000000 <= num){
        n += (Math.round(num / 10000)) + "万";
    }else if(10000 <= num){
        n += (Math.round(num / 100) / 100) + "万"
    }else{
        n += num.toLocaleString()
    }
    // console.log(num, n)

    return n;
}

/**
 * ゼロ埋め
 * @param {*} num 数字
 * @param {*} digit 桁数
 * @returns 
 */
export function zeroPadding(num, digit=2){
    if(isNaN(num) || isNaN(digit)) return NaN;

    let str = "";
    for(let i = 0; i < digit; i++) str += "0";
    str += num;

    return Number(str.slice(digit * -1));
}

/**
 * 日時をフォーマットします
 * @param {*} str 文字列またはモーメント
 * @param {*} format フォーマット文字列 初期値: YYYY年MM月DD日HH:mm:ss
 * @param {*} invalitStr 無効な日付な場合の文字列
 * @returns 
 */
export function formatDate(str, format = "YYYY年MM月DD日HH:mm:ss", invalitStr) {
    let mom = moment(str);

    if(invalitStr && !mom.isValid()) return invalitStr || mom.mom.format(format); 

    return mom.format(format);
}
