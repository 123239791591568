<template>
    <div>
        <p><a class="btn" @click="back">戻る</a></p>
        <template v-if="playlistInfo">
            <h2>「{{ playlistInfo.title }}」の編集</h2>
            <p>プレイリストの登録曲を編集します。</p>
            <div class="editingInfo">
                <h2>選択中のプレイリスト</h2>
                <div class="editingRow">
                    <div class="playlistImage">
                        <img :src="playlistInfo.image" />
                    </div>
                    <div class="playlistsContent">
                        <h2>「{{ playlistInfo.title }}」</h2>
                        <p>{{ playlistInfo.description || "説明文無し" }}</p>
                    </div>
                </div>
            </div>
            <div class="searchField">
                <p>表示件数: <select v-model="viewMax">
                        <option value="10">10件</option>
                        <option value="50">50件</option>
                    </select></p>
                <p>タイトル検索: <input list="searchName" name="searchName" v-model="searchOption_title" placeholder="タイトル"
                        @input="searchTitle" /></p>
                <datalist id="searchName">
                    <option v-for="title in titleList" :key="title" :value="title.title"></option>
                </datalist>
                <p>表示動画: <select v-model="searchOption_subsc">
                        <option value="0">すべて</option>
                        <option value="1">登録曲</option>
                    </select>
                </p>
                <p>表示順: 
                    <select v-model="searchOption_order.type">
                        <option value="0">登録日</option>
                        <option value="1">更新日</option>
                        <option value="2">投稿者別</option>
                    </select>
                    <select v-model="searchOption_order.order">
                        <option value="0">昇順</option>
                        <option value="1">降順</option>
                    </select>
                </p>
            </div>
            <div class="selectField">
                <SearchMusicArea :isFavos="true" v-model="detail" @change="getVideos"></SearchMusicArea>
            </div>
            <br />
            <PageNation v-model="current" :maxPage="maxPage" :keyObserb="false" :viewMax="viewMax"></PageNation>
            <table class="normal w100">
                <tr>
                    <th rowspan="2">&nbsp;</th>
                    <th rowspan="2">サムネイル</th>
                    <th rowspan="2">タイトル</th>
                    <th rowspan="2">投稿者</th>
                    <th>登録日</th>
                    <th rowspan="2">登録</th>
                </tr>
                <tr>
                    <th>更新日</th>
                </tr>
                <template v-if="viewList.length">
                    <PlaylistsRow v-for="video in viewList" :key="video" :video="video" :id="id" />
                </template>
                <tr v-else></tr>
            </table>
            <PageNation v-model="current" :maxPage="maxPage" :keyObserb="false" :viewMax="viewMax"></PageNation>
        </template>
        <template v-else>
            <h2>存在しない計画</h2>
            <p>選択された再生リストは存在しないか削除された可能性があります。</p>
        </template>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import PageNation from '../items/pageNation.vue';
import PlaylistsRow from './rows/playlistsRow.vue';
import SearchMusicArea from '../items/searchMusicArea.vue';
const playlistInfo = ref(null);
const videos = ref([]);
const store = useStore();
const current = ref(1);
const titleList = ref([]);

const detail = ref({
    author: "",
    isPriority_: false,
})

const emit = defineEmits(['select']);

const props = defineProps({
    selected: { type: Number, default: null }
})
const id = computed(() => {
    return props.selected;
})

const back = () => {
    emit('select', null)
}

const viewMax = ref("10");

const maxPage = computed(() => {
    return Math.ceil(videos.value.length / viewMax.value);
})

const viewList = computed(() => {
    let arry = [];
    let end = current.value * viewMax.value;
    let start = end - viewMax.value;
    arry = videos.value.slice(start, end);
    return arry;
});

//検索条件
const searchOption_subsc = ref("1");
const searchOption_title = ref("");
const searchOption_order = ref({
    type: "0",
    order: "1"
});

const searchOption = computed(() => {
    return {
        subscript: searchOption_subsc.value,
        title: searchOption_title.value,
        order: searchOption_order.value
    };
})

watch(searchOption, async () => {
    await getVideos();
}, { deep: true })

const load = async () => {
    playlistInfo.value = await store.dispatch('Api/callApi', {
        url: "private/playlist/" + id.value
    })
    await getVideos();
}
const getVideos = async () => {
    videos.value = await store.dispatch('Api/callApi', {
        url: `private/playlist/${id.value}/videos`,
        data: { searchOption: searchOption.value, detail: detail.value }
    })
}
const searchTitle = async () => {
    titleList.value = await store.dispatch('Api/callApi', {
        url: "private/searchTitle", data: {
            isAll: false,
            title: searchOption_title.value
        },
        loading: false
    })
};
load();
</script>

<style scoped>
.editingInfo {
    width: calc(100% - 30px);
    padding: 15px;
    margin: 15px;
    border-radius: 15px;
    background-color: #424549;
}

.editingRow {
    width: 100%;
    display: flex;
}

.playlistImage {
    width: 100px;
}

.playlistImage img {
    width: 100%;
}

.playlistsContent {
    padding: 0 15px;
    font-size: 16px;
}
.searchField{
    display: flex;
    justify-content: space-between;
    font-size: 24px !important;
}
.searchField input,
.searchField select {
    font-size: 24px;
}
</style>