<template>
    <div class="sunBase" :style="{left: `calc(${sunPosition.x}% + 15%)`, bottom: `${sunPosition.y}%`}">
        <img src="../../assets/weather/sun.png" />
    </div>
</template>
<script setup>
import { computed, onMounted } from 'vue';

const props = defineProps({
    time: {},
    rising: {}
})

const time = computed(() => {
    return props.time;
})

const sunrieTime = computed(() => {
    return props.rising.sunriseTime
})

const sunsetTime = computed(() => {
    return props.rising.sunsetTime
})

const sunPosition = computed(() => {
    const baseTime = sunsetTime.value.unix() - sunrieTime.value.unix();
    const nowTimeUnix = time.value.unix() - sunrieTime.value.unix();

    const percent = nowTimeUnix / baseTime;
    
    const radian = Math.PI * percent;

    const sinPI = Math.round(Math.sin(radian) * 1000) / 1000;

    //整数に下駄履きする
    let x = 1 - percent;
    let y = sinPI;
    
    //座標に補正
    x = x*70;
    y = y*70;

    return {x, y}
});

const load = () => {
    // console.log(sunrieTime.value.format('YYYY-MM-DD HH:mm'), sunsetTime.value.format('YYYY-MM-DD HH:mm'));
    // console.log(sunPosition.value)
}

onMounted(load);
</script>
<style scoped>
.sunBase{
    position: absolute;
    /* background-color: rgb(255, 153, 0); */
    width: 25px; height: 25px;
    z-index: 0;
    user-select: none;
    border-radius: 50%;
}
.sunBase img {
    width: 100%; height: 100%;
    -webkit-user-drag: none;
}
</style>