<template>
    <div>
        <h2>動画公開情報監視</h2>
        <p> 再公開される見込みがある動画を監視することができます。<br />最大1日1回公開情報を取得します。</p>
        <br />
        <p>監視対象追加: <input type="text" v-model="addUrl" placeholder="https://youtu.be/xxxx"/><a class="btn" @click="addObserving">追加</a></p>
        <table class="normal w100">
            <tr>
                <th>URL</th>
                <th>サムネイル</th>
                <th>タイトル</th>
                <th>登録日</th>
                <th>最終確認日</th>
                <th></th>
            </tr>
            <tr></tr>
            <tr v-if="observingLists.length == 0">
                <td colspan="6">登録されている動画がありません</td>
            </tr>
            <template v-else>
                <tr class="observeList" v-for="video in observingLists" :key="video">
                    <td><a :href="`https://youtu.be/${video.url}`" target="_blank" >{{ `https://youtu.be/${video.url}` }}</a></td>
                    <td><img :src="video.thumb" alt="取得失敗"/></td>
                    <td>{{ video.title || "取得失敗" }}</td>
                    <td>{{ formatDate(video.insertAt) }}</td>
                    <td>{{ formatDate(video.lastSearchedAt) }}</td>
                    <td><a class="btn" @click="deleteObserving(video.url)">削除</a></td>
                </tr>
            </template>
        </table>
    </div>
</template>
<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import error from '@/assets/error.png'
import { formatDate } from "@/utils/utils"

export default {
    setup() {
        const store = useStore();
        const observingLists = ref([]);
        const addUrl = ref();
        const load = async () => {
            observingLists.value = await store.dispatch("Api/callApi", { url: "private/observingList" });
        };
        load();
        const addObserving = async () => {
            const resp = await store.dispatch("Api/callApi", { url: "private/addObserving", data: {url: addUrl.value} });

            if(resp) {
                await store.dispatch("Common/showModal", { text: "追加しました" });
                
            }
            addUrl.value = "";
            load();
        }
        const deleteObserving = async (url) => {
            let conf = await store.dispatch('Common/openConfirm', {header: "削除確認", text: `監視対象を削除しますか？`})
            if(!conf) return;
            const resp = await store.dispatch("Api/callApi", { url: "private/removeObserving", data: {url: url} });

            if(resp) {
                await store.dispatch("Common/showModal", { text: "削除しました" });
            }
            load();
        }
        return {
            observingLists,
            addUrl,
            addObserving,
            error,
            formatDate,
            deleteObserving
        };
    },
    components: {  }
}
</script>
<style scoped>
.observeList a:not(.btn){
    color: white;
}
.observeList img{
    width: 192px;
}
</style>