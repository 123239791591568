<template>
    <div class="privateContent">
        <h2 class="w100 textCenter">設定</h2>
        <table class="normal w100 spTable">
            <tr>
                <th rowspan="4">パスワード<br />変更</th>
                <th>現在の<br />パスワード</th>
                <td><input type="password" v-model="pass1" /></td>
            </tr>
            <tr>
                <th>新しい<br />パスワード</th>
                <td><input type="password" v-model="pass2" /></td>
            </tr>
            <tr>
                <th>確認</th>
                <td><input type="password" v-model="pass3" /></td>
            </tr>
            <tr>
                <td colspan="2"><a class="spBtn" @click="changePass">変更</a></td>
            </tr>
            <tr>
                <th>お気に入り公開設定</th>
                <td colspan="2"><ToggleSwitch @change="reccomendSettingUpdate" v-model="recommendSetting" :label="`${recommendSetting ? '' : '非'}公開`"></ToggleSwitch></td>
            </tr>
            <tr>
                <th>選曲モード1</th>
                <th>アルゴリズム<br />自動選択</th>
                <td><ToggleSwitch
                        v-model="isAutoAlgos"
                        :label="isAutoAlgos ? 'ON' : 'OFF'"
                        @change="autoAlgosChange"
                ></ToggleSwitch></td>
            </tr>
            <tr>
                <th>選曲モード2</th>
                <td colspan="2">
                    <select :style="{width: '100%', height: '50px', textAlign: 'center'}" v-model="algosValue">
                        <option value="0">全期再生回数</option>
                        <option value="1">短期再生回数</option>
                        <option value="2">再生回数順</option>
                    </select>
                </td>
            </tr>
        </table>

        <br />
        <h2 class="w100 textCenter">ログイン歴</h2>
        <PageNation
            :maxPage="maxPage"
            v-model="current"
            :keyObserb="true"
        ></PageNation>
        <table class="normal w100">
            <tr>
                <th rowspan="2">現在の情報</th>
                <th>IPアドレス</th>
                <td colspan="2">{{ nowIp }}</td>
            </tr>
            <tr>
                <th>端末情報</th>
                <td colspan="2">{{ nowAgent }}</td>
            </tr>
            <tr>
                <th>日時</th>
                <th>IPアドレス</th>
                <th>端末情報</th>
            </tr>
            <tr v-for="h in history" :key="h">
                <td>{{formatDate(h.insertAt)}}</td>
                <td>{{h.IP}}</td>
                <td>{{h.agent}}</td>
            </tr>
        </table>
    </div>
</template>
<script>
import PageNation from '@/components/items/pageNation.vue';
import ToggleSwitch from '@/components/items/toggleSwitch.vue';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { formatDate } from "../../../utils/utils"

export default {
    setup() {
        const store = useStore();
        const pass1 = ref("");
        const pass2 = ref("");
        const pass3 = ref("");
        const recommendSetting = ref(false);
        const algosValue = ref("");
        const isAutoAlgos = ref(true);
        const nowIp = ref("");
        const nowAgent = ref("");
        const history = ref([]);
        const viewMax = 8;
        const current = ref(1);

        const load = async () => {
            recommendSetting.value = await store.dispatch('Api/callApi', {url: "private/getPublishFav"});
            let userValue = await store.dispatch('Api/callApi', {url: "private/algosSetting"});
            // console.log(userValue);
            algosValue.value = userValue.algos;
            isAutoAlgos.value = userValue.autoAlgos ? true : false;

            let res = await store.dispatch('Api/callApi', {url: "private/getIpAddr"});
            nowIp.value = res.ip;
            nowAgent.value = res.agent;
            history.value = res.history;

            console.log(historyView.value);
        }

        const maxPage = computed(() => {
               return Math.ceil(history.value.length / viewMax);
        })

        const changePass = async () => {
            let err = [];
            if (!pass1.value)
                err.push("現在のパスワードは必須です");
            if (!pass2.value)
                err.push("パスワードは必須です");
            if (!pass3.value)
                err.push("パスワード確認は必須です");
            if (pass2.value != pass3.value)
                err.push("パスワードと確認が一致していません");
            if (err.length) {
                await store.dispatch("Common/showModal", { text: err.join("<br />") });
                return;
            }
            let data = {
                oldPass: pass1.value,
                newPass: pass2.value
            };
            let r = await store.dispatch("Api/callApi", { url: "private/changePassword", data });
            if (r) {
                await store.dispatch("Common/showModal", { text: "変更しました。" });
            }
            else {
                await store.dispatch("Common/showModal", { text: "変更に失敗しました" });
            }
        };
        const reccomendSettingUpdate = async () => {
            await store.dispatch('Api/callApi', {url: "private/changePublishFav", data: {fav: recommendSetting.value}});
        }
        const autoAlgosChange = async () => {
            await store.dispatch('Api/callApi', {url: "private/algoAutoChanger", data: {value: isAutoAlgos.value}});
        }

        const historyView = computed(() => {
            let end = current.value * viewMax;
            let start = end - viewMax;
            return history.value.slice(start, end);
        })

        load();
        return {
            pass1,
            pass2,
            pass3,
            recommendSetting,
            isAutoAlgos,
            algosValue,
            history: historyView,
            nowIp,
            nowAgent,
            maxPage,
            current,
            changePass,
            reccomendSettingUpdate,
            autoAlgosChange,
            formatDate
        };
    },
    components: { ToggleSwitch, PageNation }
}
</script>
<style>
.spTable{
    white-space: nowrap;
}
.spTable tr input{
    width: 100%; height: 50px !important;
}
</style>