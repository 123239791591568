<template>
    <div class="quizBody">
        <div class="quizGenerator">
            <h2>クイズジェネレーター</h2>
            <br />
            <p>
                タイトル: 
                <input id="title" type="text" v-model="title" placeholder="§e中部地方は全部で何県あるでしょう？" />
            </p>
            <palletCmpVue :id="'title'" v-model="title"></palletCmpVue>
            <p>
                答え: 
                <input id="answer" type="text" v-model="answer" placeholder="§cA: 8県 §9B: 9県" />
            </p>
            <palletCmpVue :id="'answer'" v-model="answer"></palletCmpVue>

            <h2>テンプレート</h2>
            <a class="btn" @click="tempAb">ABクイズ</a>

            <div class="previewField">
                <img src="../assets/background.png" />
                <div class="previewText">
                    <p class="previewTitle" v-html="previewTitle"></p>
                    <p class="previewAnswer" v-html="previewAnswer"></p>
                </div>
            </div>

            <h2>正解指定</h2>
            <div class="AnswerArea">
                <label class="answerRed" :class="{selected: ANS=='1'}">
                    赤
                    <input type="radio" name="ans" value="1" v-model="ANS" />
                </label>
                <label class="answerBlue" :class="{selected: ANS=='0'}" >
                    <p>青</p>
                    <input type="radio" name="ans" value="0" v-model="ANS" />
                </label>
            </div>

            <br />
            <a class="btn" @click="submit">作成</a>
        </div>
    </div>
</template>
<script>
import palletCmpVue from '@/components/quiz/palletCmp.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
export default {
    components: {
        palletCmpVue
    },
    setup(){
        const title = ref("");
        const answer = ref("");
        const ANS = ref("");
        const store = useStore();

        const reg = /§[0-9a-z]{1}/g
        // const color = ["§0", "§1", "§2", "§3", "§4", "§5", "§6", "§7", "§8", "§9", "§a", "§b", "§c", "§d", "§e", "§f", "§g"];
        const color = {
            "§0": "#000",
            "§1": "#0000AA",
            "§2": "#00AA00",
            "§3": "#00AAAA",
            "§4": "#AA0000",
            "§5": "#AA00AA",
            "§6": "#FFAA00",
            "§7": "#AAAAAA",
            "§8": "#555555",
            "§9": "#5555FF",
            "§a": "#55FF55",
            "§b": "#55FFFF",
            "§c": "#FF5555",
            "§d": "#FF55FF",
            "§e": "#FFFF55",
            "§f": "#FFFFFF",
            "§g": "#DDD605"
        }

        const compile = (text) => {         
            let array = [];  //色
            let array2 = []; //修飾

            // console.log(text);
            let match = text.match(reg);
            // console.log(match);

            for(let i in match){
                let m = match[i];
                // console.log(m);
                if(color[m]){
                    let rpl = "";
                    if(array.length){
                        rpl += "</font>";
                        array.pop();
                    }
                    rpl += `<font color="${color[m]}">`;
                    array.push(m);
                    text = text.replace(m, rpl);
                }else{
                    switch(m){
                        case "§l":
                            text = text.replace(m, '<b>');
                            array2.push('</b>');
                            break;
                        case "§m":
                            text = text.replace(m, '<s>');
                            array2.push('</s>');
                            break;
                        case "§n":
                            text = text.replace(m, '<decoN>');
                            array2.push('</decoN>');
                            break;
                        case "§o":
                            text = text.replace(m, "<decoO>");
                            array2.push("</decoO>");
                            break;
                        case "§r":
                            text = text.replace(m, array2.pop());
                            break;
                    }
                }
            }

            while(array.length){
                array.pop();
                text += "</font>";
            }

            while(array2.length){
                text += array2.pop();
            }

            return text;
        }

        const previewTitle = computed(() => {
            return compile(title.value);
        })

        const previewAnswer = computed(() => {
            return compile(answer.value);
        })

        const tempAb = () => {
            title.value = "§e中部地方は全部で何県あるでしょう？";
            answer.value = "§cA: 8県 §9B: 9県"
        }

        const submit = async () => {
            let err = [];
            if(ANS.value == ""){
                err.push("正解指定がされていません");
            }
            if(previewTitle.value == ""){
                err.push("問題文が入力されていません");
            }
            if(previewAnswer.value == ""){
                err.push("答えが入力されていません");
            }

            if(err.length){
                await store.dispatch('Common/showModal', {text: err.join("<br />")});
                return;
            }

            let res = await store.dispatch('Api/callNormalApi', {url: "quiz/generate", data: {title: title.value, answer: answer.value, ANS: ANS.value}});
            
            if(res){
                let a = document.createElement('a');
                a.href = res;
                a.download = "title.mcfunction";
                console.log(a);
                a.click();
                await store.dispatch('Common/showModal', {text: "ダウンロードを開始しました"});
                a.remove();
            }
        }

        return {
            title,
            answer,
            previewTitle,
            previewAnswer,
            tempAb,
            ANS,
            submit
        }
    }
}
</script>
<style scoped>
html,body,#app{
    width: 100% !important; height: 100% !important;
    min-height: auto !important; min-width: auto !important;
}
.quizBody{
    width: 100%; height: 100%;
    background-color: antiquewhite;
    display: flex;
    justify-content: center;
    align-items: center;
}
.quizGenerator{
    width: 80%; height: 80%;
    padding: 30px;
    border: 2px solid;
    border-radius: 30px;
    background-color: white;
    text-align: center;
    font-size: 30px;
    overflow: auto;
}
input{
    font-size: 30px;
    width: 80%;
}
.previewField{
    width: 960px; height: 540px; 
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.previewText{
    position: absolute;
    width: 100%;
    top: 50%; left: 0;
    transform: translateY(-50%);
}
.previewField img{
    width: 100%; height: 100%;
}

.previewTitle,
.previewAnswer{
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: white;
    -webkit-text-stroke: 1px black;
}

.previewTitle{
    font-size: xx-large;
}
.previewAnswer{
    font-size: x-large;
}
@import url("../css/quiz.css");

/* .AnswerArea{
    display: flex;
} */
.AnswerArea label{
    border: 3px solid black;
    display: inline-block;
    padding: 0.5em 1em;
    cursor: pointer;
    user-select: none;
}
.AnswerArea input{
    display: none;
}
.answerBlue{
    background-color: rgb(201, 205, 253);
    border-color: blue !important;
}
.answerBlue.selected{
    background-color: rgb(109, 109, 248);
    border-color: blue !important;
    color: white;
}

.answerRed{
    background-color: rgb(255, 175, 175);
    border-color: red !important;
}
.answerRed.selected{
    background-color: rgb(255, 89, 89);
    border-color: red !important;
    color: white;
}
</style>
