<template>
    <div class="privateContent">
        <h2 class="w100 textCenter">登録曲</h2>
        <SpSearchArea v-model="setting" @change="load"></SpSearchArea>

        <PageNation v-model="current" :maxPage="maxPage" :keyObserb="true"></PageNation>
        <table class="normal w100" v-if="viewList">
            <tr>
                <th></th>
                <th>サムネイル</th>
                <th>投稿者</th>
                <th>再生回数</th>
                <th>登録者数</th>
                <th></th>
            </tr>
            <tr v-if="viewList.length == 0"><td colspan="6">登録されている曲がありません。</td></tr>
            <SpMusicRow v-for="f in viewList" :key="f" :video="f"></SpMusicRow>
        </table>
        <PageNation v-model="current" :maxPage="maxPage"></PageNation>
    </div>
</template>
<script>
import PageNation from '@/components/items/pageNation.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import SpSearchArea from '../item/spSearchArea.vue';
import SpMusicRow from '../row/spMusicRow.vue'

export default {
    setup() {
        const store = useStore();
        const musics = ref([]);
        const current = ref(1);
        const maxPage = computed(() => {
            return Math.ceil(musics.value.length / setting.value.count);
        });
        const setting = ref({
            count: "10",
            title: "",
            sort: {
                type: "3",
                order: "1"
            },
            author: "",
        });
        const load = async () => {
            musics.value = await store.dispatch('Api/callApi', {url: 'private/musicsInfo', data: {
                sortType: setting.value.sort.type,
                sortRange: setting.value.sort.order,
                name: setting.value.title,
                author: setting.value.author,
            }});
        }
        load();

        const checkMax = (end) => {
            current.value = end
        }

        const viewList = computed(() => {
            let arry = [];
            let end = current.value * setting.value.count;
            let start = end - setting.value.count;
            // console.log(start, end);
            if(end < current.value) checkMax(end);
            arry = musics.value.slice(start, end);
            return arry;
        })

        return {
            setting,
            load,
            current,
            maxPage,
            viewList
        }
    },
    components: { SpSearchArea, PageNation, SpMusicRow }
}
</script>