<template>
    <div class="back-fields">
        <div class="inputFields">
            <iconTemp />
            <h2>お知らせ購読</h2>
            <hr/>
            <p><b>ユーザID</b></p>
            <p><input type="text" v-model="userId" readonly disabled /></p>
            <p><b>Discord表示名</b></p>
            <p><input type="text" v-model="name" readonly disabled /></p>
            <p><b>購読ステータス</b></p>
            <p><toggleSwitch v-model="status" /></p>
            <p><b>トークン</b></p>
            <p><input v-model="token" /><a class="btn" @click="generateToken">発番</a></p>
            <hr />
            <p><a class="btn" :class="{disabled: !isCheck}" @click="update">変更</a></p>
        </div>
    </div>
</template>

<script setup>
import iconTemp from '@/components/items/iconTemp.vue';
import toggleSwitch from '@/components/items/toggleSwitch.vue';
import { computed } from 'vue';
import { ref } from 'vue';
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
const userId = ref();
const route = useRoute();
const status = ref(false);
const prevStatus = ref(false);
const name = ref("");
const token = ref("");

const router = useRouter();

const store = useStore();

const load = async () => {
    userId.value = route.params.id;
    const resp = await store.dispatch('Api/callApi', {
        url: `sbsc/${userId.value}`
    })

    if(resp == null) {
        await store.dispatch('Common/showModal', {text: "ユーザが取得できませんでした"});
        return router.push('/');
    }

    status.value = resp.status;
    name.value = resp.name;
    prevStatus.value = resp.status;
}

const generateToken = async () => {
    ///sbsc/token/
    await store.dispatch('Api/callApi', {url: `sbsc/token/${userId.value}`})
}

const isCheck = computed(() => {
    return userId.value && status.value != undefined && token.value && status.value != prevStatus.value
})

const update = async () => {
    if(!isCheck.value) return;

    // await store.dispatch('Common/showModal', {text: ""});
    const resp = await store.dispatch('Api/callApi', {
        url: `sbsc/update/${userId.value}/${token.value}`,
        data: {
            status: status.value
        }
    })


    if(resp) {
        await store.dispatch('Common/showModal', {text: status.value ? "お知らせメッセージを購読しました" : "お知らせメッセージの購読を解除しました"});
        router.push("/")
    }else{
        await store.dispatch('Common/showModal', {text: "購読情報の更新に失敗しました。"});
    }
}

onMounted(load);
</script>

<style scoped>
@import url("../css/make.css");
</style>