<template>
    <component :is="component" :selected="selecting" @select="selecting=$event" />
</template>

<script setup>
import { computed, ref } from 'vue';
import playlists from './playLists.vue';
import editingPlaylist from './editingPlaylist.vue';

const component = computed(() => {
    if(selecting.value == null) return playlists;
    else return editingPlaylist;
})

const selecting = ref(null);
</script>