<template>
    <div>
        <h2>コピートークン</h2>
        <div class="copyTokenField">
            <div class="copyTokenFieldColum">
                <h3>トークン入力</h3>
                <p>
                    共有されたトークンを入力するとお気に入り一覧が表示されます。<br />
                    トークンの有効期限は30分です。
                </p>
                <p><input type="text" placeholder="トークン" @keydown.enter="search" v-model="inputToken" /><a class="btn" @click="search">検索</a></p>
            </div>
            <div class="copyTokenFieldColum">
                <h3>トークン発行</h3>
                <p>
                    トークンを発行後、お気に入りを共有したいユーザーにトークンを共有してください<br />
                    トークンの有効期限は30分です。
                </p>
                <p>
                    <input type="text" v-model="myCpyToken" placeholder="トークンなし" readonly @click="copy2ClipboardWrap(true)" />
                    <a v-if="myCpyToken" class="btn" @click="issueCopyToken('更新')">更新</a>
                    <a v-else class="btn" @click="issueCopyToken('発行')">発行</a>
                </p>
            </div>
        </div>
        <hr />
        <div v-if="userInfo" class="cpyTokenUserInfo">
            <h2>トークンの所有者</h2>
            <p><img class="avatorIcon" :src="userInfo.avatar || userInfo.defaultAvatar"/>{{userInfo.name}}</p>
        </div>
        <hr />
        <template v-if="favos.length">
            <div class="selectField">
                <p>表示件数: <select v-model="viewMax">
                    <option value="10">10件</option>
                    <option value="50">50件</option>
                </select></p>
                <p><a class="btn" @click="allRegist">すべてお気に入り登録</a></p>
                <p>ソート順: 
                <select v-model="sortType" @change="search">
                    <option value="0">登録日</option>
                    <option value="1">週間再生回数</option>
                    <option value="2">全期再生回数</option>
                    <option value="3">再生順</option>
                </select>
                <select v-model="sortRange" @change="search">
                    <option value="0">昇順</option>
                    <option value="1">降順</option>
                </select>
                </p>
            </div>
            <pageNation
                v-model="current"
                :maxPage="maxPage"
                :keyObserb="true"
                :viewMax="viewMax"
            ></pageNation>
            <table class="normal w100">
                <tr>
                    <th class="thumbnailsFav">サムネイル</th>
                    <th>タイトル</th>
                    <th>投稿者</th>
                    <th>登録日</th>
                    <th>最終再生日</th>
                    <th></th>
                </tr>
                <blkRowVue v-for="vl in viewList" :key="vl" :vl="vl"></blkRowVue>
                <tr v-if="favos.length == 0">
                    <td colspan="5" class="center">お気に入りに登録されている曲がありません。</td>
                </tr>
            </table>
            <pageNation
                v-model="current"
                :maxPage="maxPage"
                :viewMax="viewMax"
            ></pageNation>
        </template>
    </div>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import blkRowVue from './rows/favosRowToken.vue';
import pageNation from '../items/pageNation.vue';

export default {
    components: {
        blkRowVue,
        pageNation
    },
    setup(){
        const store = useStore();
        const myCpyToken = ref("");
        const sortType = ref("0");
        const sortRange = ref('1');
        const viewMax = ref("10");
        const favos = ref([]);
        const min = ref(0);
        const inputToken = ref();
        const userInfo = ref();
        const current = ref(1);

        const maxComp = computed(() => {
            return favos.value.length
        })


        const viewList = computed(() => {
            let arry = [];
            let end = current.value * viewMax.value;
            let start = end - viewMax.value;
            // console.log(start, end);
            arry = favos.value.slice(start, end);
            return arry;
        });

        const getMyCopyToken = async () => {
            myCpyToken.value = await store.dispatch('Api/callApi', {url: "private/getMyCopyToken"});
        }
        
        const issueCopyToken = async (label) => {
            let res = await store.dispatch('Api/callApi', {url: "private/issueCopyToken"});
            if(res){
                await getMyCopyToken();
                await store.dispatch('Common/showModal', {text: "コピートークンを"+label+"しました<br />クリップボードにコピーしました"});
                copy2Clipboard(myCpyToken.value);
            }
        }

        const search = async () => {
            let res = await store.dispatch('Api/callApi', {url: "private/getCopyTokenInfo", data: {token: inputToken.value, sort: sortRange.value, type: sortType.value}});
            userInfo.value = res.user
            favos.value = res.favorites || [];
            // console.log(favos.value);
        }

        const copy2Clipboard = (text, modal) => {
            navigator.clipboard.writeText(text);
            if(modal) store.dispatch('Common/showModal', {text: "クリップボードにコピーしました"});
        }
        const copy2ClipboardWrap = (modal) => {
            if(myCpyToken.value) copy2Clipboard(myCpyToken.value, modal);
        }

        const load = async () => {
            await getMyCopyToken();
        }
        load();

        const allRegist = async () => {
            let res = await store.dispatch('Api/callApi', {url: "private/registCpyTokenAll", data: {token: inputToken.value}});
            if(res) {
                store.dispatch('Common/showModal', {text: `${res.cnt}曲登録しました`});
                search();
            }
        }

        const maxPage = computed(() => {
            return Math.ceil(favos.value.length / viewMax.value);
        })

        return {
            myCpyToken,
            issueCopyToken,
            copy2ClipboardWrap,
            sortRange,
            sortType,
            viewList,
            viewMax,
            favos,
            maxComp,
            min,
            search,
            inputToken,
            userInfo,
            allRegist,
            current,
            maxPage
        }
    }
}
</script>