<template>
    <Teleport to="body">
        <div class="bg-modal fadeIn" @click="close"></div>
        <div class="authorListModal fadeIn">
            <div class="authorListModalInner">
                <table class="normal w80 authorList">
                <tr class="header">
                    <th>&nbsp;</th>
                    <th>アイコン</th>
                    <th>名前</th>
                    <th>登録者数</th>
                    <th>登録動画</th>
                    <th>&nbsp;</th>
                </tr>
                <tr v-if="authorList.length == 0">
                    <td colspan="6"><p class="fixText">登録されている投稿者がありません</p></td>
                </tr>
                <template v-else>
                    <tr v-for="author in authorList" :key="author">
                        <td :style="{backgroundColor: author.color}"></td>
                        <td><img class="userThumb" :src="author.user_thumbnails" /></td>
                        <td>{{ author.name }}</td>
                        <td class="rightText">{{ subscFormat(author.subscriber_count) }}人</td>
                        <td class="rightText">{{ author.registed }}曲</td>
                        <td><a class="btn" @click="put(author.name)">入力</a></td>
                    </tr>
                </template>
            </table>
            </div>
        </div>
    </Teleport>
</template>

<script>
import { subscFormat } from '@/utils/utils';
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    props: {
        isFavos: {type: Boolean, default: false}
    },
    setup(props, context) {
        const store = useStore();
        const authorList = ref([]);

        const load = async () => {
            console.log(props.isFavos)
            authorList.value = await store.dispatch('Api/callApi', {
                url: "private/authorListCnt",
                data: {
                    userOnly: props.isFavos
                }
            });
            // console.log(authorList.value);  //debug
            // authorList.value = []; //debug
        }

        const put = (text) => {
            context.emit('put', text);
            close();
        }

        const close = () => {
            context.emit('close');
        }

        load();
        return {
            authorList,
            close,
            subscFormat,
            put
        }
    }
}
</script>
<style scoped>
.authorListModal{
    position: fixed;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    background-color: #424549;
    width: 60%; height: 80%;
    min-width: 800px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    
}
.authorListModalInner{
    width: calc(100% - 60px); height: calc(100% - 60px);
    margin: 30px;
    overflow: auto;
}
.userThumb{
    height: 100px;
}
.authorList{
    width: 100%;
    color: white;
}
.authorList a.btn{
    color: black;
}
.rightText{
    text-align: right;
}
.bg-modal{
    cursor: pointer;
}
</style>