<template>
    <div class="privateContent" id="privateContent">
        <div class="topRow videoPreview w100" v-if="current" @click="info = current">
            <!-- プレヴュー -->
            <img :src="current.thumbnails" />
            <p>{{ current.title }}</p>
        </div>
        <div class="latestInfo" v-if="latestInfo && nowMyCnt">
            <h2><u>現在の情報</u></h2>
            <p>登録曲数(実質): <u>{{latestInfo.realCnt}}曲</u></p>
            <p>登録曲数(名目): <u>{{latestInfo.allCnt}}曲</u></p>
            <p>あなたの登録曲数: <u>{{latestInfo.mineCnt}}曲</u></p>
            <p>平均: <u>{{ Math.floor(avg * 100) / 100 }}回</u>,標準偏差: <u>{{ Math.floor(stdev * 100) / 100 }}</u></p>
            <p>今期累計再生回数(自分): <u>{{nowMyCnt.current}}回/ {{nowMyCnt.played}}回</u>(全期)</p>
            <p>累計再生時間(自分): <u>{{totalLength_mine_Comp}}</u></p>
            <p>今期累計再生回数(全体): <u>{{latestInfo.totalView.current}}回/ {{latestInfo.totalView.played}}回</u>(全期)</p>
            <p>累計再生時間(全体): <u>{{totalLengthComp}}</u></p>
        </div>
        <div class="topRow reccomend w100">
            <h2 class="w100 textCenter">おすすめ</h2>
            <spImagesVue :videos="reccomand" @current="currentChanger($event)"></spImagesVue>
        </div>
        <div class="topRow w100">
            <h2 class="w100 textCenter">最近聞かれた曲</h2>
            <spImagesVue :videos="latestListening" @current="currentChanger($event)"></spImagesVue>
        </div>
        <div class="topRow w100">
            <h2 class="w100 textCenter">今週の再生回数TOP</h2>
            <spImagesVue :videos="thisWeek" @current="currentChanger($event)"></spImagesVue>
        </div>
        <div class="topRow w100">
            <h2 class="w100 textCenter">お気に入りTOP</h2>
            <spImagesVue :videos="ranking" @current="currentChanger($event)"></spImagesVue>
        </div>
        <div class="topRow w100">
            <h2 class="w100 textCenter">再生数TOP</h2>
            <spImagesVue :videos="viewRanking" @current="currentChanger($event)"></spImagesVue>
        </div>
    </div>
    <spMusicModal v-if="info" @close="info = null" :video="info"></spMusicModal>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import spImagesVue from "../item/spImages.vue";
import spMusicModal from '../item/spMusicModal.vue';

export default {
    setup(){
        const store = useStore();
        const current = ref(null);
        const reccomand = ref([]);
        const latestListening = ref([]);
        const ranking = ref([]);
        const viewRanking = ref([]);
        const thisWeek = ref([]);
        const info = ref(null);
        const latestInfo = ref();
        const nowMyCnt = ref();
        const avg = ref(0);
        const stdev = ref(0);
        const totalLength = ref();

        const load = async () => {
            reccomand.value = await store.dispatch('Api/callApi', {url: "private/reccomand"});
            latestListening.value = await store.dispatch('Api/callApi', {url: "private/latestListening"});
            ranking.value = await store.dispatch('Api/callApi', {url: "private/ranking"});
            viewRanking.value = await store.dispatch('Api/callApi', {url: "private/viewRanking"});
            thisWeek.value = await store.dispatch('Api/callApi', {url: "private/getThisWeek"});
            latestInfo.value = await store.dispatch('Api/callApi', {url: 'private/latestInfo'});
            nowMyCnt.value = await store.dispatch('Api/callApi', {url: "private/myPlayCnt"});
            totalLength.value = await store.dispatch('Api/callApi', {url: "private/getTotalLength"});
            let rsp = await store.dispatch('Api/callApi', {url: "private/calcSTDEV"});
            stdev.value = rsp.stdev;
            avg.value = rsp.avg;

            if(reccomand.value.length) {
                current.value = reccomand.value[0];
            }
        }
        load();

        const scrollTop = () => {
            document.getElementById('privateContent').scrollTo({
                top: 0,
                behavior: "smooth"
            })
        }

        const totalLengthComp = computed(() => {
            if(!totalLength.value) return "取得中";

            let text = totalLength.value;
            let current = text.current;
            let played = text.played;

            return `${current.hour}時間${(current.minute)}分 / ${played.hour}時間${played.minute}分(全期)`;
        })

        const totalLength_mine_Comp = computed(() => {
            if(!nowMyCnt.value) return "取得中";

            let current = nowMyCnt.value.current_time;
            let played = nowMyCnt.value.played_time;

            return `${current.hour}時間${current.minute}分 / ${played.hour}時間${played.minute}分(全期)`;
        })

        const currentChanger = (video) => {
            current.value = video;
            scrollTop();
        }

        return {
            reccomand,
            current,
            latestInfo,
            currentChanger,
            totalLengthComp,
            totalLength_mine_Comp,
            latestListening,
            ranking,
            stdev,
            avg,
            nowMyCnt,
            viewRanking,
            thisWeek,
            info,
            totalLength,
            scrollTop
        }
    },
    components: {
        spImagesVue,
        spMusicModal
    }
}
</script>
<style scoped>
.videoPreview img {
    width: 100%;
}
.videoPreview p{
    width: 100%;
    text-align: center;
    font-weight: bold;
    text-overflow: hidden;
    text-decoration: underline;
    overflow: hidden;
}
.videoPreview{
    background-color: #424549;
}
.topRow{
    margin-bottom: 10px;
}
</style>