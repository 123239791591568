import router from '@/router';
import { v4 } from "uuid";
import createPersitstedState from 'vuex-persistedstate';
const state = ()=> ({
    loading: [],
    viewModal: false,
    modalContent: "",
    modalMove: false,
    menuId: 1,
    isConfirm: false,
    confirmValue: null,
    confirmText: "",
    confirmHeader: "",
    isMember: false
});
const mutations = {
    resetLoading(state){
        state.loading = [];
    },
    addLoadingContent(state, token){
        state.loading.push(token);
    },
    removeLoadingContent(state, token){
        let index = state.loading.indexOf(token);
        state.loading.splice(index, 1);
    },
    showModal(state, obj){
        state.viewModal = true;
        state.modalContent = obj.text;
        state.modalMove = obj.move ? obj.move : false;

        // console.log(state);
    },
    closeModal(state){
        state.viewModal = false;
        if(state.modalMove){
            router.push('/');
        }
        // console.log(state);
    },
    setMenu(state, num){
        state.menuId = num;
    },
    openConfirm(state, obj) {
        state.confirmValue = null;
        state.confirmText = obj.text;
        state.confirmHeader = obj.header || "確認"
        state.isConfirm = true;
    },
    closeConfirm(state) {
        state.confirmText = "";
        state.confirmHeader = "";
        state.confirmValue = null;
        state.isConfirm = false;
    },
    setConfirmValue(state, value) {
        state.confirmValue = value;
    },
    isMember(state, value) {
        state.isMember = value
    }
};
const actions = {
    addLoadingContent(context, token_){
        const token = token_ ? token_ : v4();
        context.commit("addLoadingContent", token);
        return token;
    },
    removeLoadingContent(context, token){
        context.commit("removeLoadingContent", token);
    },
    resetLoading(context){
        context.commit("resetLoading");
    },
    showModal(context, obj){
        context.commit('showModal', obj);
    },
    closeModal(context, obj){
        context.commit('closeModal', obj);
    },
    setMenu(context, num){
        context.commit('setMenu', num);
    },
    developOnly(context) {
        if(process.env.NODE_ENV != 'development') {
            //not
            context.dispatch('showModal', {text: "現在作成中のため表示することができません"});
            context.dispatch('setMenu', 0);
        }
    },
    setIsConfirm(context, value) {
        context.commit('setIsConfirm', value)
    },
    openConfirm(context, text) {
        context.commit('openConfirm', text);
        // console.log('open');
        return new Promise((res) => {
            const interval = setInterval(() => {
                // console.log('hoge')
                if(context.state.confirmValue != null) {
                    clearInterval(interval);
                    res(context.state.confirmValue);
                    context.commit('closeConfirm');
                }
            }, 200)
        })
    },
    closeConfirm(context) {
        context.commit('closeConfirm');
    },
    setConfirmValue(context, value){
        context.commit('setConfirmValue', value);
    },
    async isMember(context) {
        const value = await context.dispatch('Api/callApi', {url: "member/"}, {root: true})
        // console.log(value);
        context.commit('isMember', value);
    }
};
const getters = {
    getLoading: state => {
        return state.loading;
    },
    getModalView: state => {
        return state.viewModal;
    },
    getModalContent: state => {
        return state.modalContent;
    },
    getModalMove: state => {
        return state.modalMove;
    },
    getMenu: state => {
        return state.menuId;
    },
    getIsConfirm: state => {
        return state.isConfirm;
    },
    getConfirmText: state => {
        return {
            text: state.confirmText,
            header: state.confirmHeader || "確認"
        }
    },
    isMember: state => {
        return state.isMember
    }
};

export default{
    namespaced: true,
    state: state(),
    mutations,
    actions,
    getters,
    plugins: [createPersitstedState({
        strage: window.sessionStorage
    })]
}