<template>
    <Teleport to="body">
        <div class="bg-modal" @click="closeModal"></div>
        <div class="musicModalContent fadeIn" :style="{backgroundColor: music.domColor}">
            <img class="thumbnails" style="cursor: pointer;" @click="openURL(`https://youtu.be/${music.url}`)" :src="music.thumbnails" />
            <div class="context">
                <p><a class="funcAURL" @click="openURL(`https://youtu.be/${music.url}`)"><b>{{music.title}}</b></a></p>
                <hr />
                <div v-if="music.author.id" class="authorContent" @click="openURL(music.author.user_url)">
                    <img class="authorThumb" :src="music.author.thumbnails || ''" />
                    <p><a>{{music.author.name}}</a><br />チャンネル登録者数: {{music.author.subscriber_count != undefined ? subscFormat(music.author.subscriber_count) + "人" : "非公開"}}</p>
                </div>
                <div v-else class="authorContent" title="取得中">
                    <img class="authorThumb" src="@/assets/Icon.png" />
                    <p><a>NiceMusic♪</a><br />投稿者情報取得中</p>
                </div>
                <div class="videoInfo">
                    <p>YouTube再生回数: {{subscFormat(music.viewCount)}}回<br /></p>
                    <p v-if="music.played !== undefined && music.current !== undefined"><span v-if="kind != 'allFavos'">自分の</span>再生回数: {{subscFormat(music.current)}}回/ {{subscFormat(music.played)}}回(全期)</p>
                    <p>BOT内再生回数    :  <span v-if="music.currentCnt === undefined">{{subscFormat(list)}}回</span><span v-else>{{subscFormat(music.currentCnt)}}回/ {{subscFormat(list)}}回(全期)</span></p>
                    <p>お気に入り登録者数: {{subscFormat(fav)}}人</p>
                    
                    <div class="registedLists" v-if="usersData">
                        <template v-if="usersData.favorites.length">
                            <p><b>お気に入り登録者(公開者のみ): {{usersData.favorites.length}}人</b></p>
                            <div class="registedListsUsers">
                                <UsersIcon
                                    v-for="fv in usersData.favorites"
                                    :key="fv"
                                    :userData="usersData.usersData[fv]"
                                ></UsersIcon>
                            </div>
                        </template>
                        <template v-if="usersData.blocked.length">
                            <p><b>ブロック者: {{usersData.blocked.length}}人</b></p>
                            <div class="registedListsUsers">
                                <UsersIcon
                                    v-for="fv in usersData.blocked"
                                    :key="fv"
                                    :userData="usersData.usersData[fv]"
                                ></UsersIcon>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="changeButtons" v-if="musicStatus && kind != 'playlist'">
                    <a class="favoButton changeButton" @click="toggleFav" :class="{added: musicStatus.favorite}">登録{{musicStatus.favorite ? "解除" : ""}}</a>
                    <a class="blockButton changeButton" @click="toggleBlk" :class="{added: musicStatus.blocked}">ブロック{{musicStatus.blocked ? "解除" : ""}}</a>
                    <a v-if="joining" class="changeButton orderBtn" @click="AddOrder()">予約</a>
                    <a v-if="isAdmin" class="changeButton updateBtn" @click="refreshInfo">更新</a>
                </div>

                <template v-if="musicStatus && musicStatus.favorite && kind != 'playlist'">
                    <p class="priorityVideo">優先動画: <ToggleSwitch v-model="musicStatus.priority" @change="priorityChange"></ToggleSwitch></p>
                </template>
                <template v-if="musicVolume !== null && kind != 'playlist'">
                    <p class="musicVolume">個別音量: <input type="number" v-model="musicVolume" @change="musicVolumeSet" step="0.1" min="-1" max="1" /></p>
                </template>
            </div>
        </div>
    </Teleport>
</template>
<style scoped>
@import url('../../css/modal.css');
.bg-modal{
    cursor: pointer;
}
.funcAURL{
    text-decoration: underline;
    cursor: pointer;
}
.context{
    margin: 15px; padding: 5px;
    border-radius: 5px;
    overflow: auto;
    background-color: rgba(54, 57, 62, 0.8)
}
.authorContent{
    margin-top: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 30px;
    background-color: #1e2124;
    margin-bottom: 10px;
}
.authorContent .authorThumb{
    width: 40px;
}
.authorContent p{
    margin-left: 1em;
    /* text-decoration: underline; */
    font-weight: bold;
    font-size: 16px;
}

.musicVolume input {
    text-align: right;
}
</style>
<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { subscFormat } from '@/utils/utils';
import UsersIcon from '../items/usersIcon.vue';
import ToggleSwitch from '../items/toggleSwitch.vue';


export default {
    props: {
        music: Object,
        kind: String,
        mytop: Boolean
    },
    setup(props, context) {
        // console.log(props.music);
        const store = useStore();
        const musicInfoText = ref("");
        const musicStatus = ref();
        const fav = ref(0);
        const list = ref(0);
        const joining = ref();
        const usersData = ref(null);
        const isAdmin = ref(false);
        const musicVolume = ref(0);
        const closeModal = function () {
            context.emit("closeMusicModal");
        };
        const openURL = async (url) => {
            const cnf = await store.dispatch('Common/openConfirm', {header: "外部のページ", text: `外部のページに飛びます。<br />よろしいですか？<br />URL: ${url}`});
            if (cnf) {
                let a = document.createElement("a");
                a.href = url;
                a.target = "_blank";
                a.click();
                a.remove();
            }
        };
        const load = async () => {
            isAdmin.value = await store.dispatch("Api/callApi", {url: 'private/isAdmin'});
            musicInfoText.value = "";
            switch (props.kind) {
                case "favorite":
                    musicInfoText.value = `お気に入り登録者数: ${subscFormat(props.music.cnt)}人`;
                    break;
                case "view":
                    musicInfoText.value = `BOT再生回数: ${subscFormat(props.music.cnt)}回`;
                    break;
            }
            musicStatus.value = await store.dispatch("Api/callApi", { url: "private/userMusicStatus", data: { url: props.music.url } });
            musicVolume.value = musicStatus.value.volume;
            let stt = await store.dispatch("Api/callApi", { url: "private/getVideoStatus", data: { url: props.music.url } });
            fav.value = stt["fav"];
            list.value = stt["played"];
            joining.value = await store.dispatch("Api/callApi", { url: "private/joiningListeningVC" });
            // console.log(joining.value);
            usersData.value = await store.dispatch("Api/callApi", { url: "private/getRegistedUser", data: {
                    url: props.music.url
                } });
            // console.log(usersData.value);
            // console.log(props.music.author)
        };
        load();
        const toggleFav = async () => {
            let url = props.music.url;
            await store.dispatch("Api/callApi", { url: "private/changeMusicStatus", data: { url, kind: "favorite", length: props.music.length } });
            musicStatus.value = await store.dispatch("Api/callApi", { url: "private/userMusicStatus", data: { url: props.music.url } });
            let stt = await store.dispatch("Api/callApi", { url: "private/getVideoStatus", data: { url: props.music.url } });
            fav.value = stt["fav"];
            list.value = stt["played"];
        };
        const toggleBlk = async () => {
            let url = props.music.url;
            await store.dispatch("Api/callApi", { url: "private/changeMusicStatus", data: { url, kind: "block", length: props.music.length } });
            musicStatus.value = await store.dispatch("Api/callApi", { url: "private/userMusicStatus", data: { url: props.music.url } });
            let stt = await store.dispatch("Api/callApi", { url: "private/getVideoStatus", data: { url: props.music.url } });
            fav.value = stt["fav"];
            list.value = stt["played"];
        };
        const AddOrder = async () => {
            //await store.dispatch('Common/showModal', {text: "変更しました。"});
            let data = {
                vid: props.music.url,
                gid: joining.value.gid
            };
            let resp = await store.dispatch("Api/callApi", { url: "private/AddOrder", data });
            if (resp) {
                await store.dispatch("Common/showModal", { text: "予約しました。" });
            }
            else {
                await store.dispatch("Common/showModal", { text: "予約に失敗しました。" });
            }
        };

        const refreshInfo = async () => {
            const resp = await store.dispatch('Api/callApi', {url: "private/updateMusicInfo", data: {url: props.music.url }})

            if(resp) {
                await store.dispatch("Common/showModal", { text: resp.message });
                if(resp.result){
                    load();
                }else{
                    context.emit("closeMusicModal");
                }
            }
        }

        const priorityChange = async () => {
            await store.dispatch('Api/callApi', {url: "private/changeMusicPriority", data: {url: props.music.url, value: musicStatus.value.priority }})
            context.emit('priorityUpdate');
            // load();
        }

        const musicVolumeSet = async () => {
            if(musicVolume.value < -1 || 1 < musicVolume.value) {
                await store.dispatch('Common/showModal', {text: "音量は-1以上1以下である必要があります。"})
                if(musicVolume.value < -1) {
                    musicVolume.value = -1;
                }else {
                    musicVolume.value = 1;
                }
                return;
            }

            let resp = await store.dispatch('Api/callApi', {url: "private/changeMusicVolume", data: {
                url: props.music.url, volume: musicVolume.value
            }});

            musicVolume.value = resp;
        }

        return {
            closeModal,
            openURL,
            subscFormat,
            musicInfoText,
            musicStatus,
            toggleBlk,
            toggleFav,
            fav,
            list,
            joining,
            AddOrder,
            usersData,
            isAdmin,
            refreshInfo,
            priorityChange,
            musicVolume,
            musicVolumeSet
        };
    },
    components: { UsersIcon, ToggleSwitch }
}
</script>
<style scoped>
.updateBtn{
    color: rgb(118, 150, 3);
    border-color: rgb(118, 150, 3);
    transition: 0.5s;
}
.updateBtn:hover{
    color: white;
    background-color: rgb(118, 150, 3);
}
.updateBtn:active{
    transition: none;
    transform: scale(0.9)
}
.priorityVideo{
    display: flex;
    align-items: center;
}
</style>