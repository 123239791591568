<template>
    <div class="passwordInput">
        <input :type="isShow ? `text` : `password`" v-model="value" @input="input"  @keydown.enter="enter()" />
        <img v-if="!isShow" class="passwordShower" src="../../assets/eye.png" title="パスワードを表示" @click="isShow = !isShow" />
        <img v-else class="passwordShower" src="../../assets/eye_not.png" title="パスワードを非表示" @click="isShow = !isShow" />
    </div>
</template>
<script>
import { ref, watch, computed } from 'vue';

export default {
    props: {
        modelValue: {type: String, default: ""}
    },
    setup(props, context) {
        const value = ref();
        const isShow = ref(false);
        const load = () => {
            value.value = props.modelValue;
        }
        load();

        const comp = computed(() => {
            return props.modelValue;
        })
        watch(comp, (v) => {
            value.value = v;
        })

        const input = () => {
            // console.log(value.value)
            context.emit('update:modelValue', value.value)
        }

        const enter = () => {
            context.emit('enter');
        }

        return {
            value,
            input,
            isShow,
            enter
        }
    }
}
</script>
<style scoped>
.passwordInput{
    position: relative;
    display: inline-block;
}
.passwordShower{
    position: absolute;
    height: 28px;
    right: 10px;
    cursor: pointer;
    user-select: none;
}
</style>